/* tslint:disable */
import {
  Store,
  Admin_company,
  BalanceHistory,
  Group,
  OrderCompany,
  CertificateCategory,
  Ticket,
  Certificate,
  User,
  CertSchedule,
  Question,
  Response
} from '../index';

declare var Object: any;
export interface CompanyInterface {
  "name"?: string;
  "logo"?: string;
  "logoWhite"?: string;
  "graphic"?: string;
  "banner"?: string;
  "color"?: string;
  "code"?: string;
  "isActive"?: boolean;
  "rfc"?: string;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  "attendantName"?: string;
  "attendantEmail"?: string;
  "attendantPhone"?: string;
  "country"?: string;
  "state"?: string;
  "zipCode"?: string;
  "suburb"?: string;
  "street"?: string;
  "addressNumber"?: string;
  "businessName"?: string;
  "typePerson"?: string;
  "isDeleted"?: boolean;
  "typePlan"?: string;
  "theme"?: string;
  "terms"?: string;
  "expirationDate"?: Date;
  "value"?: number;
  "periodicity"?: string;
  "owners"?: Array<any>;
  "phrase"?: string;
  "id"?: number;
  "userId"?: number;
  stores?: Store[];
  adminCompanies?: Admin_company[];
  balanceHistories?: BalanceHistory[];
  groups?: Group[];
  orderCompanies?: OrderCompany[];
  categories?: CertificateCategory[];
  tickets?: Ticket[];
  certificates?: Certificate[];
  admins?: User;
  users?: User[];
  schedules?: CertSchedule[];
  questions?: Question[];
  answers?: Response[];
}

export class Company implements CompanyInterface {
  "name": string;
  "logo": string;
  "logoWhite": string;
  "graphic": string;
  "banner": string;
  "color": string;
  "code": string;
  "isActive": boolean;
  "rfc": string;
  "createdAt": Date;
  "updatedAt": Date;
  "attendantName": string;
  "attendantEmail": string;
  "attendantPhone": string;
  "country": string;
  "state": string;
  "zipCode": string;
  "suburb": string;
  "street": string;
  "addressNumber": string;
  "businessName": string;
  "typePerson": string;
  "isDeleted": boolean;
  "typePlan": string;
  "theme": string;
  "terms": string;
  "expirationDate": Date;
  "value": number;
  "periodicity": string;
  "owners": Array<any>;
  "phrase": string;
  "id": number;
  "userId": number;
  stores: Store[];
  adminCompanies: Admin_company[];
  balanceHistories: BalanceHistory[];
  groups: Group[];
  orderCompanies: OrderCompany[];
  categories: CertificateCategory[];
  tickets: Ticket[];
  certificates: Certificate[];
  admins: User;
  users: User[];
  schedules: CertSchedule[];
  questions: Question[];
  answers: Response[];
  constructor(data?: CompanyInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Company`.
   */
  public static getModelName() {
    return "Company";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Company for dynamic purposes.
  **/
  public static factory(data: CompanyInterface): Company{
    return new Company(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Company',
      plural: 'Companies',
      path: 'Companies',
      idName: 'id',
      properties: {
        "name": {
          name: 'name',
          type: 'string'
        },
        "logo": {
          name: 'logo',
          type: 'string'
        },
        "logoWhite": {
          name: 'logoWhite',
          type: 'string'
        },
        "graphic": {
          name: 'graphic',
          type: 'string'
        },
        "banner": {
          name: 'banner',
          type: 'string'
        },
        "color": {
          name: 'color',
          type: 'string'
        },
        "code": {
          name: 'code',
          type: 'string'
        },
        "isActive": {
          name: 'isActive',
          type: 'boolean'
        },
        "rfc": {
          name: 'rfc',
          type: 'string'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date',
          default: new Date(0)
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date',
          default: new Date(0)
        },
        "attendantName": {
          name: 'attendantName',
          type: 'string'
        },
        "attendantEmail": {
          name: 'attendantEmail',
          type: 'string'
        },
        "attendantPhone": {
          name: 'attendantPhone',
          type: 'string'
        },
        "country": {
          name: 'country',
          type: 'string'
        },
        "state": {
          name: 'state',
          type: 'string'
        },
        "zipCode": {
          name: 'zipCode',
          type: 'string'
        },
        "suburb": {
          name: 'suburb',
          type: 'string'
        },
        "street": {
          name: 'street',
          type: 'string'
        },
        "addressNumber": {
          name: 'addressNumber',
          type: 'string'
        },
        "businessName": {
          name: 'businessName',
          type: 'string'
        },
        "typePerson": {
          name: 'typePerson',
          type: 'string'
        },
        "isDeleted": {
          name: 'isDeleted',
          type: 'boolean',
          default: false
        },
        "typePlan": {
          name: 'typePlan',
          type: 'string'
        },
        "theme": {
          name: 'theme',
          type: 'string'
        },
        "terms": {
          name: 'terms',
          type: 'string'
        },
        "expirationDate": {
          name: 'expirationDate',
          type: 'Date'
        },
        "value": {
          name: 'value',
          type: 'number'
        },
        "periodicity": {
          name: 'periodicity',
          type: 'string'
        },
        "owners": {
          name: 'owners',
          type: 'Array&lt;any&gt;'
        },
        "phrase": {
          name: 'phrase',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "userId": {
          name: 'userId',
          type: 'number'
        },
      },
      relations: {
        stores: {
          name: 'stores',
          type: 'Store[]',
          model: 'Store',
          relationType: 'hasMany',
          modelThrough: 'Company_store',
          keyThrough: 'storeId',
          keyFrom: 'id',
          keyTo: 'companyId'
        },
        adminCompanies: {
          name: 'adminCompanies',
          type: 'Admin_company[]',
          model: 'Admin_company',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'companyId'
        },
        balanceHistories: {
          name: 'balanceHistories',
          type: 'BalanceHistory[]',
          model: 'BalanceHistory',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'companyId'
        },
        groups: {
          name: 'groups',
          type: 'Group[]',
          model: 'Group',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'companyId'
        },
        orderCompanies: {
          name: 'orderCompanies',
          type: 'OrderCompany[]',
          model: 'OrderCompany',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'companyId'
        },
        categories: {
          name: 'categories',
          type: 'CertificateCategory[]',
          model: 'CertificateCategory',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'companyId'
        },
        tickets: {
          name: 'tickets',
          type: 'Ticket[]',
          model: 'Ticket',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'companyId'
        },
        certificates: {
          name: 'certificates',
          type: 'Certificate[]',
          model: 'Certificate',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'companyId'
        },
        admins: {
          name: 'admins',
          type: 'User',
          model: 'User',
          relationType: 'belongsTo',
                  keyFrom: 'userId',
          keyTo: 'id'
        },
        users: {
          name: 'users',
          type: 'User[]',
          model: 'User',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'companyId'
        },
        schedules: {
          name: 'schedules',
          type: 'CertSchedule[]',
          model: 'CertSchedule',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'companyId'
        },
        questions: {
          name: 'questions',
          type: 'Question[]',
          model: 'Question',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'companyId'
        },
        answers: {
          name: 'answers',
          type: 'Response[]',
          model: 'Response',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'companyId'
        },
      }
    }
  }
}
