import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-cancel-scheduled-dialog',
  templateUrl: './cancel-scheduled-dialog.component.html',
  styleUrls: ['./cancel-scheduled-dialog.component.scss']
})
export class CancelScheduledDialogComponent implements OnInit {
  static box = {
    width: '90%',
    maxWidth: '400px',
    maxHeight: '90vh'
  };
  agree = false;
  loading = false;

  constructor(
    private dialogRef: MatDialogRef<CancelScheduledDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private scheduled,
    private apiService: ApiService) { }

  ngOnInit() {
  }

  async close() {
    this.loading = true;
    let res = await this.apiService.apiSynchronize('deleteSchedule', { certId: this.scheduled.id.toString() });
    this.loading = false;
    if (!res) return;
    this.apiService.schedule = this.apiService.schedule.filter(schedule => schedule.id != this.scheduled.id);
    this.dialogRef.close(true);
  }

}
