import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.scss']
})
export class ScheduleComponent implements OnInit {
  tabTitle = 'Envíos programados';
  navLinks = [{
    path: ['colaboradores'],
    label: 'Colaboradores',
    icon: 'person'
  }];
  constructor() { }

  ngOnInit() {
  }

}
