import { Component, OnInit } from '@angular/core';
import { formatNumber } from '@angular/common';
import { MatDialog } from '@angular/material';
import * as moment from 'moment';
import { CertHistoryDialogComponent } from 'src/app/components'
import { ApiService } from 'src/app/services/api.service';
import { Angular5Csv } from 'angular5-csv/Angular5-csv';

@Component({
  selector: 'app-history-users',
  templateUrl: './history-users.component.html',
  styleUrls: ['./history-users.component.scss']
})
export class HistoryUsersComponent implements OnInit {
  p: number = 1;
  loading = false;
  target = 'user';
  targetLabel = 'colaborador';

  filter = {
    month: null,
    category: null,
    name: null
  }

  columns = [
    {
      id: 'status',
      label: 'Estatus',
      width: 100, thClass: 'pr-3',
      tdClass: 'pr-3'
    },
    {
      id: 'createdAt',
      label: 'Fecha',
      width: 110, thClass: 'px-3',
      tdClass: 'px-3 border-left'
    },
    {
      id: 'user.name',
      label: 'Nombre del ' + this.targetLabel,
      width: 190, thClass: 'px-3',
      tdClass: 'px-3 border-left'
    },
    {
      id: 'total',
      label: 'Monto',
      width: 80, thClass: 'px-3',
      tdClass: 'px-3 border-left'
    },
    {
      id: 'id',
      label: 'ID',
      width: 60, thClass: 'px-3',
      tdClass: 'px-3 border-left'
    },
    {
      id: 'category.name',
      label: 'Categoría',
      width: 150, thClass: 'px-3',
      tdClass: 'px-3 border-left'
    },
    {
      id: 'actions',
      label: '',
      width: 95, thClass: 'pl-3',
      tdClass: 'pl-3 border-left'
    },
  ];

  constructor(protected apiService: ApiService, private dialog: MatDialog) { }

  async ngOnInit() {
    
    let arr2sync = [];
    if (this.apiService.certs.length) arr2sync.push(false);
    else arr2sync.push(this.apiService.apiSynchronize('certs'));
    if (this.apiService.certCategories.length) arr2sync.push(false);
    else arr2sync.push(this.apiService.apiSynchronize('certCategories'));

    this.loading = true;
    let [certs, categories] = await Promise.all(arr2sync);
    this.loading = false;

    if (certs) {
      certs.forEach(cert => {
        switch (cert.status) {
          case 'available':
            cert.status = 'Activado'
            break;
          case 'inBalance':
              cert.status = 'Disponible'
              break;
          case 'changed':
            cert.status = 'Cambiado'
            break;
          case 'expirated':
            cert.status = 'Expirado'
            break;
            case 'expired':
              cert.status = 'Expirado'
              break;
        }
      });
      this.apiService.certs = certs.reverse();
    } 
    
    if (categories) this.apiService.certCategories = categories;
  }

  get months() {
    return moment.months();
  }

  get categories() {
    return this.apiService.certCategories;
  }

  get certificates() {
    return this.apiService.certs
      .filter(cert => {
        let show = true;
        if (this.filter.month !== null)
          show = moment(cert.createdAt).month() === this.filter.month;
        if (this.filter.category !== null)
          show = show && this.filter.category === cert.categoryId;
        if (this.filter.name) {
          console.log(cert);
          
          let name = cert.user.name.toLocaleLowerCase();
          let search = this.filter.name.toLocaleLowerCase();
          show = show && name.includes(search);
        }
        return show;
      });
  }

  processData(certLine, col) {
    switch (col.id) {
      case 'total': {
        return '$' + formatNumber(certLine[col.id], 'en', '2.2-2');
      }
      case 'createdAt': {
        return moment(certLine[col.id]).format('L');
      }
      case 'date': {
        return moment.utc(certLine[col.id]).format('L - hh:mm a');
      }
      case 'user.name': {
        return (certLine.user || {}).name;
      }
      case 'group.name': {
        return certLine.group.name;
      }
      case 'category.name': {
        return certLine.category.name;
      }
      default: {
        return certLine[col.id];
      }
    }
  }

  statusIcon(status) {
    return 'gift'
  }

  statusLabel(status) {
    return status
  }

  openCertHistoryDialog(cert) {
    this.dialog.open(CertHistoryDialogComponent, {
      ...CertHistoryDialogComponent.box,
      data: cert
    });
  }

  downloadReport() {  
    let certs = this.apiService.certs;
    if(this.filter.category && this.filter.month){
      let filtro = this.apiService.certs.filter(cr => cr.categoryId == this.filter.category);
      certs = filtro.filter(c => moment(c.createdAt).month() == this.filter.month);
    }
    else if(!this.filter.category && this.filter.month){
      certs = this.apiService.certs.filter(c => moment(c.createdAt).month() == this.filter.month);
    }
    else if(this.filter.category && !this.filter.month){
      certs = this.apiService.certs.filter(cr => cr.categoryId == this.filter.category);
    }
    let data: any = [{
      title:'Reporte de Historial de Certificados',
      fecha: 'Fecha: ' + moment(new Date()).format('DD/MMMM/YYYY hh:mm a')
    },
    {
      name: 'Nombre de colaborador',
      email: 'Correo',
      status: 'Estatus',
      date: 'Fecha',
      amount: 'Monto',
      category: 'Categoría'      
    }];
    certs.forEach((cert) => {
      data.push({
        name: cert.user.name,
        email: cert.user.email,
        status: cert.status,
        date: moment(cert.createdAt).format('LL'),
        amount: cert.value,
        category: cert.category.name
      });
    });
    const archivo = new Angular5Csv(data, 'Reporte-de-Historial-de-Certificados_'+ moment().format("DD/MM/YYYY-hh:mm a"));
  }
}
