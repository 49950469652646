import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filterUsers'
})
export class FilterUsersPipe implements PipeTransform {

    transform(mailUsers: string[], filter: string): any {
        if (!mailUsers || !filter) return mailUsers;
        return mailUsers.filter(mail => mail.toLowerCase().includes(filter.toLowerCase()));
    }

}
