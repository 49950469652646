import { BreakpointsComponent } from "./breakpoints/breakpoints.component";
import { CalendarDialogComponent } from "./dialogs/calendar-dialog/calendar-dialog.component";
import { CancelScheduledDialogComponent } from "./dialogs/cancel-scheduled-dialog/cancel-scheduled-dialog.component";
import { CardColorComponent } from "./card-color/card-color.component";
import { CertCardComponent } from "./cert-card/cert-card.component";
import { CertCategoriesDialogComponent } from "./dialogs/cert-categories-dialog/cert-categories-dialog.component";
import { CertDialogComponent } from "./dialogs/cert-dialog/cert-dialog.component";
import { CertFormComponent } from "./cert-form/cert-form.component";
import { CertHistoryDialogComponent } from "./dialogs/cert-history-dialog/cert-history-dialog.component";
import { CertResumeDialogComponent } from "./dialogs/cert-resume-dialog/cert-resume-dialog.component";
import { CompanyInfoComponent } from "./company-info/company-info.component";
import { ErrorDialogComponent } from "./dialogs/error-dialog/error-dialog.component";
import { ExchangeCertDialogComponent } from "./dialogs/exchange-cert-dialog/exchange-cert-dialog.component";
import { FaqsComponent } from "./faqs/faqs.component";
import { FooterComponent } from "./footer/footer.component";
import { ForwardInvitationDialogComponent } from "./dialogs/forward-invitation-dialog/forward-invitation-dialog.component";
import { GroupUsersDialogComponent } from "./dialogs/group-users-dialog/group-users-dialog.component";
import { HeaderAdminComponent } from "./header-admin/header-admin.component";
import { HeaderComponent } from "./header/header.component";
import { IconsComponent } from "./icons/icons.component";
import { InstructionsComponent } from "./instructions/instructions.component";
import { LoadingComponent } from "./loading/loading.component";
import { LoginFormComponent } from "./login-form/login-form.component";
import { NewUserDialogComponent } from "./dialogs/new-user-dialog/new-user-dialog.component";
import { PageAdminComponent } from "./page-admin/page-admin.component";
import { PageComponent } from "./page/page.component";
import { ReportDialogComponent } from "./dialogs/report-dialog/report-dialog.component";
import { ReportFormComponent } from "./report-form/report-form.component";
import { ResetPassFormComponent } from "./reset-pass-form/reset-pass-form.component";
import { RouterTabsComponent } from "./router-tabs/router-tabs.component";
import { StoreDialogComponent } from "./dialogs/store-dialog/store-dialog.component";
import { UserDatesDialogComponent } from "./dialogs/user-dates-dialog/user-dates-dialog.component";
import { UserDeleteDialogComponent } from "./dialogs/user-delete-dialog/user-delete-dialog.component";
import { UserDialogComponent } from "./dialogs/user-dialog/user-dialog.component";
import { UserEmailDialogComponent } from "./dialogs/user-email-dialog/user-email-dialog.component";
import { EditQuestionComponent } from "./edit-question/edit-question.component";
import { QuestionDeleteComponent } from "./dialogs/question-delete/question-delete.component";

export {
  BreakpointsComponent,
  CalendarDialogComponent,
  CancelScheduledDialogComponent,
  CardColorComponent,
  CertCardComponent,
  CertCategoriesDialogComponent,
  CertDialogComponent,
  CertFormComponent,
  CertHistoryDialogComponent,
  CertResumeDialogComponent,
  CompanyInfoComponent,
  ErrorDialogComponent,
  ExchangeCertDialogComponent,
  FaqsComponent,
  FooterComponent,
  ForwardInvitationDialogComponent,
  GroupUsersDialogComponent,
  HeaderAdminComponent,
  HeaderComponent,
  IconsComponent,
  InstructionsComponent,
  LoadingComponent,
  LoginFormComponent,
  NewUserDialogComponent,
  PageAdminComponent,
  PageComponent,
  ReportDialogComponent,
  ReportFormComponent,
  ResetPassFormComponent,
  RouterTabsComponent,
  StoreDialogComponent,
  UserDatesDialogComponent,
  UserDeleteDialogComponent,
  UserDialogComponent,
  UserEmailDialogComponent,
  EditQuestionComponent,
  QuestionDeleteComponent
};
