import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { Angular5Csv } from 'angular5-csv/Angular5-csv';
import { QuestionDeleteComponent } from 'src/app/components';
import { ApiService } from 'src/app/services/api.service';
import { Question, QuestionApi, Response, ResponseApi, User, UserApi } from 'src/app/services/shared/sdk';
import { isNullOrUndefined } from 'util';
import * as moment from 'moment';

export enum Question_Type {
  select = 'select',
  textarea = 'textarea',
  datepicker = 'datepicker'
}

@Component({
  selector: 'app-questionary',
  templateUrl: './questionary.component.html',
  styleUrls: ['./questionary.component.scss']
})
export class QuestionaryComponent implements OnInit {

  questionForm: FormGroup = new FormGroup({})
  questionType = Question_Type
  questions: Question[]
  newQuestions: Question[] = []
  questionModel: Partial<Question> = {
    id: null,
    description: null,
    isDeleted: false,
    type: null, // tipo: campo abierto (textarea), selección 1 o multiple (select), selección de fecha (datepicker)
    options: [], // este se usará como la configuración del input field.templateOptions
    required: false,
    companyId: null
  }
  action: 'edit' | 'create'
  myQuestionary: FormGroup = new FormGroup({})
  formOptions: FormlyFormOptions = {};
  questionOptions: FormArray
  fields: FormlyFieldConfig[] = [];
  loadingPage: boolean = true
  savingQuestion: boolean = false
  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    private apiService: ApiService,
    private questionApi: QuestionApi,
    private responseApi: ResponseApi,
    private userApi: UserApi
  ) {
    this.questionForm = this.fb.group({
      type: [null, Validators.required],
      description: [null, Validators.required],
      options: this.fb.array([]),
      required: [false],
      multiple: [false]
    });
    this.apiService.editQuestion.subscribe(val => this.questionToEdit(val))
    this.apiService.deleteQuestion.subscribe(val => this.deleteQuestion(val))
  }

  async ngOnInit() {
    this.savingQuestion = false
    this.action = null
    await this.getQuestions()
    this.loadingPage = false
  }

  async getQuestions(): Promise<void> {
    const questions: Question[] = await this.questionApi.find<Question>({ where: { isDeleted: false, companyId: this.apiService._company.id }, include: [{ relation: 'responses' }] }).toPromise()
    this.questions = questions
    console.log(questions);
    this.newQuestions = []
    this.fields = await this.transformQuestionsToFormly(questions)
  }

  createOption({ value = '', label = '' }: { value?: any; label?: string; }): FormGroup {
    return this.fb.group({
      value,
      label
    });
  }

  get optionsArray(): FormArray {
    return this.questionForm.get('options') as FormArray;
  }

  addQuestionOption() {
    this.optionsArray.push(this.createOption({}));
  }

  removeQuestionOption(index: number): void {
    return this.optionsArray.removeAt(index);
  }

  onSelectQuestionType(newVal): void {
    if (this.action != 'edit') {
      this.optionsArray.clear()
      if (['select'].includes(newVal)) this.addQuestionOption()
    }
  }

  addQuestion(): void {
    let { type, description, required, options, multiple } = this.questionForm.value
    const newQuestionModel = { ...this.questionModel, ...this.questionForm.value }
    const fieldWrapper: FormlyFieldConfig = {
      wrappers: ['edit-question'],
      form: this.myQuestionary,
      fieldGroup: [],
      templateOptions: {
        question: newQuestionModel
      }
    }
    const newQuestionToForm: FormlyFieldConfig = {
      key: '',
      type,
      templateOptions: {
        question: newQuestionModel,
        required,
        label: description,
        options: options.map(option => ({ value: option.label, label: option.label })),
        multiple
      },
    }
    newQuestionModel.options = [{ ...newQuestionToForm.templateOptions, question: null }]
    this.newQuestions.push(newQuestionModel)
    fieldWrapper.fieldGroup.push(newQuestionToForm)
    this.fields = this.fields.concat(fieldWrapper)
    this.resetForm()
  }

  resetForm(): void {
    this.questionForm.reset()
    this.optionsArray.clear()
    console.log('this.questionForm value', this.questionForm.value);
  }

  async transformQuestionsToFormly(questions: Question[]): Promise<FormlyFieldConfig[]> {
    let formlyQuestions = []
    for (const question of questions) {
      let { type, description, required, options } = question
      const fieldWrapper: FormlyFieldConfig = {
        wrappers: ['edit-question'],
        form: this.myQuestionary,
        fieldGroup: [],
        templateOptions: {
          question
        }
      }

      if (isNullOrUndefined(options) || options.length > 1) {
        let newOptions = options && options.length ? [...options] : []
        options = [{
          question,
          required,
          label: description,
          options: newOptions,
          multiple: false
        }]
      }
      const newQuestionToForm: FormlyFieldConfig = {
        key: '',
        type,
        templateOptions: options[0],
      }
      // this.newQuestions.push(question)
      // console.log(newQuestionToForm);
      fieldWrapper.fieldGroup.push(newQuestionToForm)
      formlyQuestions = formlyQuestions.concat(fieldWrapper)
    }
    return formlyQuestions
  }

  questionToEdit(question: Question) {
    this.action = 'edit'
    this.optionsArray.clear()
    this.questionModel = { ...question }
    this.questionForm.patchValue({ ...question })
    if (question.options && question.options[0] && question.options[0].options) {
      this.questionForm.patchValue({ multiple: question.options[0].multiple })
      const formOptions = question.options[0].options.map(option => this.createOption(option))
      console.log('formOptions', formOptions);
      formOptions.map(opt => this.optionsArray.push(opt))
      // this.optionsArray.patchValue(formOptions)
    }
  }

  async editAndSave() {
    this.savingQuestion = true
    let { type, description, required, options, multiple } = this.questionForm.value
    const data = {
      ...this.questionModel,
      isDeleted: false,
      type,
      required,
      description,
      companyId: this.apiService._company.id,
      options: [{
        required,
        label: description,
        options: options.map(option => ({ value: option.label, label: option.label })),
        multiple
      }]
    }
    const updatedQuestion = await this.questionApi.patchOrCreate(data).toPromise()
    this.resetForm()
    this.ngOnInit()
  }

  async saveQuestionary(): Promise<void> {
    this.savingQuestion = true
    Promise.all(
      this.newQuestions.map(async (question) => {
        const data = { ...question, index: null, isDeleted: false }
        console.log('data for new question', data);
        if (!data.companyId) data.companyId = this.apiService._company.id
        const newQuestion = await this.questionApi.patchOrCreate(data).toPromise()
        console.log('newQuestion', newQuestion)
        return newQuestion
      })
    ).then(result => {
      console.log('Promise all result', result);
      this.ngOnInit();
    })
  }

  removeFromFormly(index) {
    this.fields.splice(index, 1)
  }

  deleteQuestion(question) {
    if (!question.id) {
      return this.removeFromFormly(question.index)
    }
    let dialog = this.dialog.open(QuestionDeleteComponent, {
      data: question
    });
    dialog.afterClosed().subscribe(deleted => {
      console.log('deleted', deleted);
      if (deleted) this.ngOnInit();
    });
  }


  async downloadReport() {
    /*
    + //TODO optimizar descarga.
    + Este reporte se debería de generar en el back
    + !!!!!! CÓDIGO INEFICIENTE, obtiene todas las respuestas y usuario de cada pregunta
     */
    try {
      this.loadingPage = true;
      let data: any[] = [];
      const options = {
        showTitle: true,
        title: `Reporte de Cuestionario - Fecha: ${moment(new Date()).format('DD/MMMM/YYYY hh:mm a')}`,
        nullToEmptyString: true,
        headers: [
          'Nombre',
          'Correo'
        ]
      }
      let usersWithResponses = []
      let usersResponses = []
      Promise.all(
        // Para cada pregunta, se obtendrá para cada respuesta su usuario
        this.questions.map(async (question) => {
          // agrupa responses por userId para obtener los id's de los propietarios de las respuestas
          const groupedResponsesByUser = question.responses.reduce((r, a) => {
            r[a.userId] = r[a.userId] || [];
            r[a.userId].push(a);
            return r;
          }, Object.create({}))
          var usersId = Object.keys(groupedResponsesByUser)
          var no_repeated = usersId.filter(userId => !usersWithResponses.includes(userId))
          usersWithResponses = usersWithResponses.concat(no_repeated)
          // obtiene el user propietario de cada grupo de respuestas siempre y cuando no exista ya en el arreglo de users
          const responsesOwners = await Promise.all(no_repeated.map(async userId => await this.userApi.findById<User>(userId, { fields: ['id', 'name', 'email'] }).toPromise()))
          usersResponses = usersResponses.concat(responsesOwners)
          // question_responses.push(responses)
          // options.headers.push(question.description)
          return { ...question, users: Object.keys(groupedResponsesByUser) }

        })
      ).then(questionsResolve => {
        // console.log('questionsResolve', questionsResolve)
        // console.log('usersResponses', usersResponses);
        questionsResolve.map(question => options.headers.push(question.description))
        usersResponses.forEach(user => {
          var id = user.id
          var insertCol = {
            nombre: user.name,
            correo: user.email
          }
          questionsResolve.forEach(question => {
            var response = question.responses.find(res => res.userId == id)
            insertCol[question.description] = response ? response.value : ''
          })
          data = data.concat(insertCol)
        })
        // console.log('data', data);
        const archivo = new Angular5Csv(data, `Reporte_Cuestionario_${moment().format("DD/MM/YYYY-hh:mm a")}`, options);
        this.loadingPage = false;
      })

    } catch (error) {
      this.loadingPage = false;
      console.log('error', error);

      alert('Error en el servidor')
    }
  }

}
