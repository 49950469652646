import { Component, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  @ViewChild('footer',{static :true}) foo; 
  altura = window.innerWidth;

  constructor() { }

  ngOnInit() {}

}
