import { NgModule, LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MatAutocompleteModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDialogModule,
  MatMenuModule,
  MatNativeDateModule,
  MatProgressSpinnerModule,
  MatSnackBarModule,
  MatTabsModule,
  MatTableModule,
  MatIconModule,
  MatPaginatorModule
} from '@angular/material';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  NgbAccordionModule, NgbDatepickerModule, NgbDropdownModule, NgbProgressbarModule
} from '@ng-bootstrap/ng-bootstrap';
import { ChartsModule } from 'ng2-charts';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

import {
  CalendarDialogComponent,
  CancelScheduledDialogComponent,
  CardColorComponent,
  CertCardComponent,
  CertCategoriesDialogComponent,
  CertHistoryDialogComponent,
  CertResumeDialogComponent,
  CompanyInfoComponent,
  EditQuestionComponent,
  ErrorDialogComponent,
  FaqsComponent,
  FooterComponent,
  ForwardInvitationDialogComponent,
  GroupUsersDialogComponent,
  HeaderAdminComponent,
  IconsComponent,
  LoadingComponent,
  LoginFormComponent,
  NewUserDialogComponent,
  PageAdminComponent,
  ReportFormComponent,
  ResetPassFormComponent,
  RouterTabsComponent,
  UserDatesDialogComponent,
  UserDeleteDialogComponent,
  UserDialogComponent,
  UserEmailDialogComponent,
  QuestionDeleteComponent
} from './components';

import {
  AccessComponent,
  CategoriesComponent,
  CertsComponent,
  HelpComponent,
  HistoryComponent,
  HistoryGroupsComponent,
  HistoryUsersComponent,
  HomeComponent,
  LoginComponent,
  PrivacyComponent,
  QuestionaryComponent,
  ResetPasswordComponent,
  ScheduleComponent,
  ScheduleGroupsComponent,
  ScheduleUsersComponent,
  SessionComponent,
  TermsComponent,
  UsersComponent,
  UsersGroupsComponent,
  UsersUsersComponent,
} from "./views";
import { EventsComponent } from './views/session/events/events.component';

import { NgxPaginationModule } from 'ngx-pagination';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMatDatepickerModule } from '@ngx-formly/material/datepicker';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { SDKBrowserModule } from './services/shared/sdk';
import { OrdersComponent } from './views/session/orders/orders.component';
import { InfoPedidoComponent } from './views/session/info-pedido/info-pedido.component';
import { DialogsModule } from './components/dialogs/dialogs.module';
import { NewGroupDialogComponent } from './components/dialogs/new-group-dialog/new-group-dialog.component';
import { EditGroupDialogComponent } from './components/dialogs/edit-group-dialog/edit-group-dialog.component';
import { FilterUsersPipe } from './pipes/filter-users.pipe';

registerLocaleData(es, 'es-MX');

@NgModule({
  declarations: [
    AccessComponent,
    AppComponent,
    CalendarDialogComponent,
    CancelScheduledDialogComponent,
    CardColorComponent,
    CategoriesComponent,
    CertCardComponent,
    CertCategoriesDialogComponent,
    CertHistoryDialogComponent,
    CertResumeDialogComponent,
    CertsComponent,
    CompanyInfoComponent,
    ErrorDialogComponent,
    FaqsComponent,
    FooterComponent,
    ForwardInvitationDialogComponent,
    GroupUsersDialogComponent,
    HeaderAdminComponent,
    HelpComponent,
    HistoryComponent,
    HistoryGroupsComponent,
    HistoryUsersComponent,
    HomeComponent,
    IconsComponent,
    LoadingComponent,
    LoginComponent,
    LoginFormComponent,
    NewUserDialogComponent,
    PageAdminComponent,
    PrivacyComponent,
    ReportFormComponent,
    ResetPassFormComponent,
    ResetPasswordComponent,
    RouterTabsComponent,
    ScheduleComponent,
    ScheduleGroupsComponent,
    ScheduleUsersComponent,
    SessionComponent,
    TermsComponent,
    UserDatesDialogComponent,
    UserDeleteDialogComponent,
    UserDialogComponent,
    UserEmailDialogComponent,
    UsersComponent,
    UsersGroupsComponent,
    UsersUsersComponent,
    EventsComponent,
    QuestionaryComponent,
    EditQuestionComponent,
    QuestionDeleteComponent,
    OrdersComponent,
    InfoPedidoComponent
  ],
  imports: [
    SDKBrowserModule.forRoot(),
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    DialogsModule,
    ChartsModule,
    FormsModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatMenuModule,
    MatTableModule,
    MatNativeDateModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatTabsModule,
    MatIconModule,
    MatPaginatorModule,
    NgbAccordionModule,
    NgbProgressbarModule,
    NgbDatepickerModule,
    NgbDropdownModule,
    ReactiveFormsModule,
    NgMultiSelectDropDownModule.forRoot(),
    NgxPaginationModule,
    FormlyModule.forRoot({
      extras: {
        lazyRender: true
      },
      wrappers: [
        { name: 'edit-question', component: EditQuestionComponent },
      ]
    }),
    FormlyMaterialModule,
    FormlyMatDatepickerModule
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'es-MX' },
    FilterUsersPipe
  ],
  entryComponents: [
    CalendarDialogComponent,
    CancelScheduledDialogComponent,
    CertCategoriesDialogComponent,
    CertHistoryDialogComponent,
    CertResumeDialogComponent,
    ErrorDialogComponent,
    ForwardInvitationDialogComponent,
    GroupUsersDialogComponent,
    NewUserDialogComponent,
    UserDatesDialogComponent,
    UserDeleteDialogComponent,
    UserDialogComponent,
    UserEmailDialogComponent,
    QuestionDeleteComponent,
    InfoPedidoComponent,
    NewGroupDialogComponent,
    EditGroupDialogComponent
  ],
  exports: [IconsComponent],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class AppModule { }
