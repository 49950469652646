import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-cert-history-dialog',
  templateUrl: './cert-history-dialog.component.html',
  styleUrls: ['./cert-history-dialog.component.scss']
})
export class CertHistoryDialogComponent implements OnInit {

  static box = {
    width: '90%',
    maxWidth: '400px',
    maxHeight: '80vh'
  };

  constructor(@Inject(MAT_DIALOG_DATA) private cert) { }

  ngOnInit() {
    console.log(this.cert);
    this.cert.history.forEach(cert => {
      switch (cert.status) {
        case 'available':
          cert.status = 'Activado'
          break;
        case 'inBalance':
            cert.status = 'Disponible'
            break;
        case 'changed':
          cert.status = 'Cambiado'
          break;
        case 'expirated':
          cert.status = 'Expirado'
          break;
        case 'expired':
          cert.status = 'Expirado'
          break;
      }
    });
  }

  statusIcon(s) {
    return 'gift';
  }

  status(s) {
    return s;
  }

}
