import { Component, OnInit } from '@angular/core';
import { ScheduleUsersComponent } from '../schedule-users/schedule-users.component';
import { ApiService } from 'src/app/services/api.service';
import { MatDialog, MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-schedule-groups',
  templateUrl: '../schedule-users/schedule-users.component.html',
  styleUrls: ['../schedule-users/schedule-users.component.scss']
})
export class ScheduleGroupsComponent extends ScheduleUsersComponent implements OnInit {

  constructor(
    apiService: ApiService,
    dialog: MatDialog,
    snackBar: MatSnackBar,
  ) {
    super(apiService, dialog, snackBar);
    this.label = 'grupos';
    this.scheduleType = 'group';
    this.columns[0].id = 'group.name';
    this.columns[0].label = 'Nombre del grupo';
  }
}
