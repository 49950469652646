import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-report-form',
  templateUrl: './report-form.component.html',
  styleUrls: ['./report-form.component.scss']
})
export class ReportFormComponent implements OnInit {
  loading = false;
  reportForm = new FormGroup({
    category: new FormControl('', [Validators.required]),
    description: new FormControl(null, [Validators.required])
  });
  @Input() labels: boolean = true;
  @Input() data;
  @Output() sended = new EventEmitter<boolean>();

  constructor(private apiService: ApiService) { }

  ngOnInit() {
  }

  async send() {
    this.loading = true;
    let payload = await this.apiService.report({
      ...this.reportForm.value,
      data: this.data
    });
    this.loading = false;
    if (!payload) return false;
    this.sended.emit(true);
  }

}
