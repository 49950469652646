import { Component, OnInit } from '@angular/core';
import { formatNumber } from '@angular/common';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { MatDialog, MatAutocompleteSelectedEvent, MatSnackBar } from '@angular/material';
import * as moment from 'moment';
import { ApiService } from 'src/app/services/api.service';
import { GroupUsersDialogComponent } from 'src/app/components';
import { NewGroupDialogComponent } from 'src/app/components/dialogs/new-group-dialog/new-group-dialog.component';
import { EditGroupDialogComponent } from 'src/app/components/dialogs/edit-group-dialog/edit-group-dialog.component';
import { Group_employeeApi } from 'src/app/services/shared/sdk';

@Component({
  selector: 'app-users-groups',
  templateUrl: './users-groups.component.html',
  styleUrls: ['./users-groups.component.scss']
})
export class UsersGroupsComponent implements OnInit {
  p: number = 1;
  loading = false;
  loadingNew = false;
  target = 'groups';
  targetLabel = 'grupos';
  columns = [
    {
      id: 'name',
      label: 'Nombre del grupo',
      width: 150, thClass: 'pr-3',
      tdClass: 'pr-3'
    },
    {
      id: 'users.length',
      label: 'N. de integrantes',
      width: 150, thClass: 'px-3',
      tdClass: 'px-3 border-left'
    },
    {
      id: 'lastDate',
      label: 'Último envío',
      width: 120, thClass: 'px-3',
      tdClass: 'px-3 border-left'
    },
    {
      id: 'createdAt',
      label: 'Creado el',
      width: 120, thClass: 'px-3',
      tdClass: 'px-3 border-left'
    },
    {
      id: 'next2expire',
      label: 'Certificados por vencer',
      width: 190, thClass: 'px-3',
      tdClass: 'px-3 border-left'
    },
    {
      id: 'actions',
      label: '',
      width: 120, thClass: 'pl-3',
      tdClass: 'pl-3 border-left'
    },
  ];
  showForm: boolean = false;
  formGroup = new FormGroup({
    id: new FormControl(),
    name: new FormControl(null, Validators.required),
    user: new FormControl(),
    users: new FormControl([])
  });
  usersSearch = [];
  timeout;
  edit = false;

  constructor(
    private apiService: ApiService,
    private dialog: MatDialog,
    private _groupUser: Group_employeeApi,
    private _snackBar: MatSnackBar
  ) { }

  async ngOnInit() {

    let arr2sync = [];
    if (this.apiService.groups.length) arr2sync.push(false);
    else arr2sync.push(this.apiService.apiSynchronize('groups'));
    if (this.apiService.users.length || true) arr2sync.push(false);
    else arr2sync.push(this.apiService.apiSynchronize('users'));

    this.loading = true;
    let [groups, users] = await Promise.all(arr2sync);
    this.loading = false;

    if (groups) {
      groups = groups.filter((group) => group.isDeleted == false);
      this.apiService.groups = groups;
    }

    if (users) this.apiService.users = users;

    this.getUsersSearch();
  }

  newGroup() {
    const nuevoGrupo = this.dialog.open(NewGroupDialogComponent, {
      width: '60%'
    });
    nuevoGrupo.afterClosed().subscribe((result) => {
      if (result) {
        this.apiService.groups.push(result);
      }
    })
  }

  closeNew() {
    this.showForm = false;
  }

  get groups() {
    if (!this.apiService.groups) return;
    return this.apiService.groups.map(g => g);
  }

  processData(group, col) {
    switch (col.id) {
      case 'lastDate': {
        if (!group.schedule || !group.schedule.length) return 'Sin envíos';
        return moment(group.schedule[0].date).format('L');
      }
      case 'createdAt': {
        return moment(group[col.id]).format('L');
      }
      case 'users.length': {
        return `${formatNumber(group.users.length, 'en')} colaboradores`;
      }
      case 'next2expire': {
        return `${formatNumber(group.schedule ? group.schedule.length : 0, 'en')} certificados`;
      }
      default: {
        return group[col.id];
      }
    }
  }

  openUsersDialog(group) {
    this.dialog.open(GroupUsersDialogComponent, {
      ...GroupUsersDialogComponent.box,
      data: group
    })
  }

  /* editGroup(group) {
    console.log(group)
    this.edit = true;
    this.showForm = true;
    this.formGroup.get('id').setValue(group.id);
    this.formGroup.get('name').setValue(group.name);
    this.formGroup.get('users').setValue(group.users);
  } */

  async editGroup(group, index) {
    if (!group.members) {
      const membersGroup = await this._groupUser.find({ where: { groupId: group.id }, include: { relation: 'user' } }).toPromise()
      group.members = membersGroup;
    }

    const editGroup = this.dialog.open(EditGroupDialogComponent, {
      width: '50%',
      data: { group, index }
    });
    editGroup.afterClosed().subscribe(updatedGroup => {
      if (updatedGroup) {
        const groupIndex = this.apiService.groups.findIndex(g => g.id == updatedGroup.id);
        this.apiService.groups[groupIndex] = Object.assign(this.apiService.groups[groupIndex], updatedGroup);
      }
    })
  }


  async deleteGroup(i, group) {
    this.loading = true;
    let resp = await this.apiService.apiSynchronize('deleteGroup', { id: group.id });
    this.loading = false;
    if (!resp) return;
    this.apiService.groups.splice(i, 1);
    this._snackBar.open('Grupo eliminado', 'Hecho', {
      duration: 2000
    });
  }

  getUsersSearch() {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(async () => {
      let search = this.formGroup.get('user').value;
      this.usersSearch = await this.apiService.apiSynchronize('usersSearch', { search });
    }, 300);
  }


  get usersSelected() {
    return this.formGroup.get('users').value;
  }

  remove(user) {
    let users: any[] = this.formGroup.get('users').value;
    let i = users.findIndex(u => u.id === user.id);
    users.splice(i, 1);
  }

  selected(event: MatAutocompleteSelectedEvent) {
    this.formGroup.get('user').setValue('');
    let users: any[] = this.formGroup.get('users').value;
    users.push(event.option.value);
  }

  async send() {
    let id = this.formGroup.get('id').value;
    let name = this.formGroup.get('name').value;
    let users = this.formGroup.get('users').value.map(u => u.id);
    this.loadingNew = true;
    let service = this.edit ? 'editGroup' : 'sendNewGroup';
    let group = await this.apiService.apiSynchronize(service, {
      id: this.edit ? parseInt(id) : undefined,
      name,
      companyId: this.apiService.company.id,
      users
    });
    this.loadingNew = false;
    if (!group) return;
    if (!this.edit) {
      this.apiService.groups.push({
        id: group.id,
        name,
        createdAt: group.createdAt,
        users: this.formGroup.get('users').value,
        schedule: [],
        isDeleted: group.isDeleted
      });
    }
    else {
      let group = this.apiService.groups.find(g => g.id == id);
      group.name = name;
      //se mantiene la referencia por tanto no es necesario asignar
      //group.users = this.formGroup.get('users').value;
    }
    this.showForm = false;
  }

}
