import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-cert-dialog',
  templateUrl: './cert-dialog.component.html',
  styleUrls: ['./cert-dialog.component.scss']
})
export class CertDialogComponent implements OnInit {

  static box = {
    width: '90%',
    maxWidth: '300px',
    panelClass: 'no-gutters-dialog'
  };

  constructor(@Inject(MAT_DIALOG_DATA) private cert = {}) { }

  ngOnInit() {
    console.log(this.cert);
  }

}
