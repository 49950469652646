import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-store-dialog',
  templateUrl: './store-dialog.component.html',
  styleUrls: ['./store-dialog.component.scss']
})
export class StoreDialogComponent implements OnInit {

  static box = {
    width: '90%',
    maxWidth: '400px',
    maxHeight: '80vh'
  };

  terminos = false;

  constructor(
    private apiService: ApiService,
    private dialogRef: MatDialogRef<StoreDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data) {
  }

  ngOnInit() {

  }

  close() {
    if (this.data.useToSelect) {
      let storeId = this.data.store.id;
      this.apiService.storeSetActive(storeId);
      let store = this.apiService.getStoreById(storeId);
      this.apiService.setGift({
        storeId,
        value: store.values[0]
      });
    }
    this.dialogRef.close();
  }

}
