import { Component, OnInit, ViewChild, ElementRef, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { UserApi, GroupApi, Group_employeeApi, User, Group } from '../../../services/shared/sdk';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-new-group',
  templateUrl: './new-group-dialog.component.html',
  styleUrls: ['./new-group-dialog.component.scss']
})
export class NewGroupDialogComponent implements OnInit {

  form: FormGroup;

  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  colabCtrl = new FormControl();
  filteredColaboradores: Observable<User[]>;
  colaboradores: any = [];
  allUsers: User[] = [];
  created = new Date();

  @ViewChild('colaboradorInput', { static: false })
  colaboradorInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto', { static: false })
  matAutocomplete: MatAutocomplete;

  constructor(
    public dialogRef: MatDialogRef<NewGroupDialogComponent>,
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private _user: UserApi,
    private _group: GroupApi,
    private _groupUser: Group_employeeApi,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackBar: MatSnackBar
  ) {
    this._user.find<User>({
      where: {
        and: [
          { isDeleted: false }
        ]
      }
    }).subscribe(users => {
      this.allUsers = users;
    })
    this.filteredColaboradores = this.colabCtrl.valueChanges.pipe(
      startWith(null),
      startWith(''),
      map((colab: string | null) => colab ? this._filter(colab) : this.allUsers.slice())
    );
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    const selectedUser = this.allUsers.find(u => u.email == value);
    if (!selectedUser) return;
    this.colaboradores.push(selectedUser);
    if (input) {
      input.value = '';
    }
    this.colabCtrl.setValue(null);
  }

  remove(colab: User): void {
    const index = this.colaboradores.findIndex(user => colab.email == user.email);

    if (index >= 0) {
      this.colaboradores.splice(index, 1);
    }
    this.allUsers.unshift(colab);
    this.colaboradorInput.nativeElement.value = '';
    this.colabCtrl.setValue(null);
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    const selectedUser = event.option.value;
    console.log({ selectedUser });
    this.colaboradores.push(selectedUser);
    let indexColab = this.allUsers.findIndex(u => u.email == selectedUser.email);
    console.log({ indexColab });

    this.allUsers.splice(indexColab, 1);
    this.colaboradorInput.nativeElement.value = '';
    this.colabCtrl.setValue(null);
  }

  private _filter(value: string): User[] {
    if (!value || typeof value != 'string') return this.allUsers;
    const filterValue = value.toLowerCase();
    return this.allUsers.filter(colab => colab.email.toLowerCase().indexOf(filterValue) === 0);
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      name: ['', Validators.required]
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  createGroup() {
    this._group.create({ name: this.form.value.name, companyId: this.apiService.company.id, createdAt: this.created }).subscribe((createdGroup: Group) => {
      console.log(createdGroup);
      let users = [];
      this.colaboradores.forEach((User: any) => {
        users.push({ groupId: createdGroup.id, userId: User.id, companyId: this.apiService.company.id });
      });
      this._groupUser.createMany(users).subscribe(createdRelations => {
        createdGroup.users = this.colaboradores;
        console.log(`Relaciones con el grupo creadas: ${createdRelations}.`);
        this.dialogRef.close(createdGroup);
      });
      this.snackBar.open('Grupo creado', 'Listo!', {
        duration: 2000
      });
    });
  }

}
