import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { formatNumber } from '@angular/common';
import * as moment from 'moment';
import { ChartType } from 'chart.js';
import { Label, SingleDataSet } from 'ng2-charts';
import { ApiService } from 'src/app/services/api.service';
import { MatCalendarCellCssClasses, MatCalendar } from '@angular/material';
import { Angular5Csv } from 'angular5-csv/Angular5-csv';
import { UserApi } from 'src/app/services/shared/sdk';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
  p: number = 1;
  @ViewChild('calendar', { static: true }) calendar: MatCalendar<Date>;
  loading = false;
  estadisticas: any;
  cards = [
    {
      title: 'Colaboradores activos',
      subtitle: 0,
      icon: 'people',
      color: 'green'
    },
    {
      title: 'Invitaciones pendientes',
      subtitle: 0,
      icon: 'person',
      color: 'blue'
    },
    {
      title: 'Certificados enviados',
      subtitle: 0,
      icon: 'mail',
      color: 'yellow'
    },
    {
      title: 'Certificados por expirar',
      subtitle: 0,
      icon: 'exclamation-circle',
      color: 'red'
    },
    {
      title: 'Categorías disponibles',
      subtitle: 0,
      icon: 'figures',
      color: 'purple'
    }
  ];
  period = '15left';
  firstDate = null;
  lastDate = null;
  filterDates = null;
  categories = [
    { value: 'birthdate', label: 'Cumpleaños' },
    { value: 'dateAdmission', label: 'Aniversario de trabajo' },
    { value: 'anniversaryDate', label: 'Aniversario de bodas' },
    { value: 'birthdateCouple', label: 'Cumpleaños de pareja' },
    { value: 'childrenBirthdates', label: 'Cumpleaños de hijo' }
  ];
  doughnut: {
    chartLabels: Label[];
    chartData: SingleDataSet;
    chartType: ChartType;
    options;
  } = {
      chartLabels: ['', '', '', '', '', '', '', '', '', ''],
      chartData: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      chartType: 'doughnut',
      options: {
        responsive: true,
        legend: {
          position: 'left'
        }
      }
    }
  bar: {
    chartLabels: Label[];
    chartData: any;
    chartType: ChartType;
    options;
  } = {
      chartLabels: [],
      chartData: [{ data: [0], label: 'Programados' },
      { data: [0], label: 'Enviados' },
      { data: [0], label: 'Canjeados' },
      { data: [0], label: 'Por expirar' }],
      chartType: 'bar',
      options: {
        responsive: true,
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              precision: 0
            }
          }]
        }
      }
    }
  _dates = [];
  categoriesBalance = 0;
  categoriesBalanceSubscription: Subscription;

  constructor(
    private apiService: ApiService,
    private userApi: UserApi
  ) {
    this.dateClass = this.dateClass.bind(this);
    this.categoriesBalanceSubscription = this.apiService.onUpdateCategoriesBalance.subscribe(data => {
      console.log('data home', data);

      this.categoriesBalance = data.total;
    })
  }

  async ngOnInit() {
    this.loading = true;
    let company = await this.apiService.apiSynchronize('company');
    this.apiService.company = company;

    //let users = await this.apiService.apiSynchronize('usersList');
    let users: any[] = await this.userApi.find({
      where: { companyId: this.apiService.company.id, isDeleted: false, emailVerified: true },
      include: [{
        relation: 'groupEmployees',
        scope: {
          include: ['group']
        }
      }],
      limit: 300
    }).toPromise();
    this.loading = false;

    this._dates = users.reduce((userDates, user) => {
      this.categories.forEach(({ value: field, label: categoryName }) => {
        if (!user[field]) return;
        if (field == "childrenBirthdates" && !user[field].length) return;
        if (field == 'anniversaryDate' || field == 'birthdateCouple') {
          if (user[field].day == '' && user[field].month == '') return;
        }
        const dateObj = (date) => ({
          user: user.name,
          category: field,
          categoryName,
          date: moment(date).toDate(),
        });

        if (Array.isArray(user[field])) {
          userDates.push(...user[field].map(date => dateObj(date)));
        } else {
          userDates.push(dateObj(user[field]));
        }
      });
      //console.log(userDates);

      return userDates;
    }, []);
    let notifications = [];
    this._dates.forEach(d => {
      let today = moment();
      let m = today.month();
      let dm = moment(d.date).month();
      if (m !== dm) return;
      let title = d.user + ' ';
      switch (d.category) {
        case 'birthdate':
          title += ' cumple años';
          break;
        case 'dateAdmission':
          title += ' entró a trabajar';
          break;
        case 'anniversaryDate':
          title += ' tiene su aniversario';
          break;
        case 'birthdateCouple':
          title = 'La pareja de ' + title + 'cumple años';
          break;
        case 'childrenBirthdates':
          title = 'Un hijo de ' + title + 'cumple años';
          break;
        default:
          title += d.category
          break;
      }
      notifications.push({
        title: title,
        date: d.date,
        left: today.diff(d.date, 'days')
      });
    });
    this.apiService.notifications = notifications;
    this.change();
    this.updateCalendar();
  }

  ngOnDestroy(): void {
    this.categoriesBalanceSubscription.unsubscribe();
  }

  get company() {
    return this.apiService.company;
  }

  get dates() {
    return this._dates.filter(d => this.filterDates ? this.filterDates === d.category : true);
  }

  get _firstDate() {
    if (!this.firstDate) return '';
    return moment(this.firstDate).format('DD/MM/YY');
  }

  get _lastDate() {
    if (!this.lastDate) return '';
    return moment(this.lastDate).format('DD/MM/YY');
  }

  dateFormat(d) {
    return moment(d).format('L');
  }

  title(value) {
    return '<small>' + value + '</small>';
  }

  subtitle(value) {
    return '<span class="h1">' + formatNumber(value, 'en') + '</span>';
  }

  openPicker(picker) {
    this.period = 'custom';
    picker.open();
  }

  async downloadStatistics() {
    this.loading = true;
    await this.apiService.apiSynchronize('downloadStatistics');
    this.loading = false;
  }

  async change() {
    let filter = {
      companyId: this.apiService.company.id,
      period: this.period,
      firstDate: this.firstDate,
      lastDate: this.lastDate
    };
    this.loading = true;
    let statistics = await this.apiService.apiSynchronize('statistics', filter);
    this.estadisticas = statistics;
    this.loading = false;
    if (statistics) {
      this.cards[0].subtitle = statistics.users;
      this.cards[1].subtitle = statistics.invitations;
      this.cards[2].subtitle = statistics.certificates;
      this.cards[3].subtitle = statistics.expire;
      this.cards[4].subtitle = statistics.categories;
      this.doughnut.chartLabels = statistics.stores.map(s => s.name);
      this.doughnut.chartData = statistics.stores.map(s => s.count);
      let { schedule,
        sended,
        changed,
        toExpire } = statistics.certs;
      this.bar.chartData = [
        { data: [schedule], label: schedule + ' Programados' },
        { data: [sended], label: sended + ' Enviados' },
        { data: [changed], label: changed + ' Canjeados' },
        { data: [toExpire], label: toExpire + ' Por expirar', backgroundColor: 'red' }
      ];
    }
  }

  dateClass(date: Date): MatCalendarCellCssClasses {
    let datesEqual = this.dates.filter(d => {
      let date1 = moment(date);
      let date2 = moment(d.date);

      return date1.date() === date2.date() &&
        date1.month() === date2.month();
    });
    if (datesEqual.length) {
      return 'registered ' + datesEqual.map(d => d.category).join(' ');
    } else {
      return;
    }
  }
  updateCalendar() {
    this.calendar.updateTodaysDate();
  }

  async downloadReport() {
    if (!this.estadisticas) {
      let filter = {
        companyId: this.apiService.company.id,
        period: this.period,
        firstDate: this.firstDate,
        lastDate: this.lastDate
      };
      this.loading = true;
      this.estadisticas = await this.apiService.apiSynchronize('statistics', filter);
      this.loading = false;
    }
    let data: any = [{
      title: 'Estadísticas de la plataforma',
      fecha: 'Fecha: ' + moment(new Date()).format('DD/MMMM/YYYY hh:mm a')
    },
    {
      colaboradores: 'Colaboradores Activos',
      inv: 'Invitaciones Pendientes',
      cerEnv: 'Certificados Enviados',
      cerXpi: 'Certificados por Expirar',
      categories: 'Categorías Disponibles'
    }];
    data.push({
      colaboradores: this.estadisticas.users,
      inv: this.estadisticas.invitations,
      cerEnv: this.estadisticas.certificates,
      cerXpi: this.estadisticas.expire,
      categories: this.estadisticas.categories
    });
    const archivo = new Angular5Csv(data, 'Estadisticas_' + moment().format("DD/MM/YYYY-hh:mm a"));
  }
}
