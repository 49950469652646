import { Component, ViewChild, ViewContainerRef } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-edit-question',
  templateUrl: './edit-question.component.html',
  styleUrls: ['./edit-question.component.scss']
})
export class EditQuestionComponent extends FieldWrapper {
  @ViewChild('fieldComponent', { read: ViewContainerRef, static: true }) fieldComponent: ViewContainerRef;

  constructor(
    private apiService: ApiService
  ) {
    super()
  }

  edit() {
    console.log('this.to', this.to);
    const question = this.to.question
    this.apiService.editQuestion.emit(question)
  }

  remove() {
    console.log('this.to', this.to.question);
    const question = this.to.question
    this.apiService.deleteQuestion.emit(question)
  }
}
