import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import * as moment from 'moment';
import { MatDatepicker, MatDialogRef } from '@angular/material';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-new-user-dialog',
  templateUrl: './new-user-dialog.component.html',
  styleUrls: ['./new-user-dialog.component.scss']
})
export class NewUserDialogComponent implements OnInit {

  static box = {};

  loading = false;
  newUser = new FormGroup({
    name: new FormControl(null, [Validators.required]),
    payrollNumber: new FormControl(null, [Validators.required]),
    dateAdmission: new FormControl(null, [Validators.required]),
    email: new FormControl(null, [Validators.required, Validators.email])
  });

  constructor(private apiService: ApiService, private dialog: MatDialogRef<NewUserDialogComponent>) { }

  ngOnInit() {
  }

  get dateAdmission() {
    let date = this.newUser.get('dateAdmission').value;
    if (!date) return '';
    return moment(date).format('L');
  }

  openCalendar(picker: MatDatepicker<Date>) {
    picker.open();
  }

  async send() {
    let user = this.newUser.value;
    user.password = '3' + Math.floor(1000000 + Math.random() * 9000000);
    user.pass = user.password;
    user.companyId = this.apiService.company.id;
    this.loading = true;
    let resp = await this.apiService.apiSynchronize('newUser', user);
    this.loading = false;
    if (!resp) return;
    this.dialog.close();
  }

}
