import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import * as moment from 'moment';
import { formatNumber } from '@angular/common';

@Component({
  selector: 'app-user-dialog',
  templateUrl: './user-dialog.component.html',
  styleUrls: ['./user-dialog.component.scss']
})
export class UserDialogComponent implements OnInit {

  static box = {
    width: '90%',
    maxWidth: '380px',
    maxHeight: '90vh'
  }

  constructor(@Inject(MAT_DIALOG_DATA) private user) { }

  ngOnInit() {
    console.log(this.user);
  }

  get fields() {
    return [{
      label: 'Nombre',
      value: this.user.name
    },
    {
      label: 'Email',
      value: this.user.email
    },
    {
      label: 'Estado',
      value: this.user.country || ''
    },
    {
      label: 'Número de nómina',
      value: this.user.payrollNumber
    },
    {
      label: 'Fecha de ingreso a la empresa',
      value: this.user.dateAdmission ? moment(this.user.dateAdmission).format('L') : 'N/A'
    },
    {
      label: 'Antigüedad',
      value: this.user.dateAdmission ? `${moment().diff(this.user.dateAdmission, 'years')} años` : 'N/A'
    },
    {
      label: 'Cumpleaños',
      value: this.user.birthdate ? moment(this.user.birthdate).format('DD [de] MMMM') : 'N/A'
    },
    {
      label: 'Edad',
      value: this.user.birthdate ? moment().diff(this.user.birthdate, 'years') : 'N/A'
    },
    {
      label: 'Grupos',
      value: this.user.groupEmployees.length ? this.user.groupEmployees.map(g => g.group.name).join(' · ') : '<i>Ninguno</i>'
    },
    {
      label: 'Certificados recibidos',
      value: (this.user.certs || []).length + ' certificados'
    },
    {
      label: 'Regalos canjeados',
      value: (this.user.gifts || []).length + ' regalos'
    },
    {
      label: 'Saldo disponible',
      value: `$${formatNumber(this.user.balance || 0, 'en', '1.2-2')} MXN`
    }
    ];
  }

}
