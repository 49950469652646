import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthService } from './services/auth.service';
import { NotAuthService } from './services/not-auth.service';
import {
  AccessComponent,
  CategoriesComponent,
  CertsComponent,
  HelpComponent,
  HistoryComponent,
  HistoryGroupsComponent,
  HistoryUsersComponent,
  HomeComponent,
  LoginComponent,
  PrivacyComponent,
  ResetPasswordComponent,
  ScheduleComponent,
  ScheduleGroupsComponent,
  ScheduleUsersComponent,
  SessionComponent,
  TermsComponent,
  UsersComponent,
  UsersGroupsComponent,
  UsersUsersComponent,
  EventsComponent,
  QuestionaryComponent,
  OrdersComponent
} from "./views";

const routes: Routes = [
  {
    path: '', component: SessionComponent,
    canActivate: [AuthService],
    children: [
      { path: '', redirectTo: 'inicio', pathMatch: 'full' },
      { path: 'inicio', component: HomeComponent },
      { path: 'programar-envio', component: CertsComponent },
      { path: 'categorias', component: CategoriesComponent },
      { path: 'eventos', component: EventsComponent },
      { path: 'cuestionario', component: QuestionaryComponent },
      { path: 'pedidos', component: OrdersComponent },
      {
        path: 'envios-programados', component: ScheduleComponent,
        children: [
          { path: '', redirectTo: 'colaboradores', pathMatch: 'full' },
          { path: 'colaboradores', component: ScheduleUsersComponent },
          { path: 'grupos', component: ScheduleGroupsComponent },
        ]
      },
      { path: 'envios-programados/:id', component: CertsComponent },
      {
        path: 'colaboradores', component: UsersComponent,
        children: [
          { path: '', redirectTo: 'colaboradores', pathMatch: 'full' },
          { path: 'colaboradores', component: UsersUsersComponent },
          { path: 'grupos', component: UsersGroupsComponent },
        ]
      },
      {
        path: 'historial', component: HistoryComponent,
        children: [
          { path: '', redirectTo: 'colaboradores', pathMatch: 'full' },
          { path: 'colaboradores', component: HistoryUsersComponent },
          { path: 'grupos', component: HistoryGroupsComponent },
        ]
      },
      { path: 'ayuda', component: HelpComponent },
      // { path: 'terminos', component: TermsComponent },
      { path: 'aviso-privacidad', component: PrivacyComponent }
    ]
  },
  {
    path: '', component: AccessComponent,
    canActivate: [NotAuthService],
    children: [
      { path: 'acceso', component: LoginComponent },
      { path: 'restablecer', component: ResetPasswordComponent }
    ]
  },
  { path: '**', pathMatch: 'full', redirectTo: 'acceso' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
