import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import * as moment from 'moment';
import { ApiService } from 'src/app/services/api.service';
import {
  ForwardInvitationDialogComponent,
  NewUserDialogComponent,
  UserDatesDialogComponent,
  UserDeleteDialogComponent,
  UserDialogComponent,
  UserEmailDialogComponent,
} from 'src/app/components';
import { CampusApi, CertificateCategoryApi, Group_employeeApi, UserApi } from 'src/app/services/shared/sdk';
import { Angular5Csv } from 'angular5-csv/Angular5-csv';

@Component({
  selector: 'app-users-users',
  templateUrl: './users-users.component.html',
  styleUrls: ['./users-users.component.scss']
})
export class UsersUsersComponent implements OnInit {
  p1: number = 1;
  p2: number = 1;

  loading = false;

  invtColumns = [
    {
      id: 'name',
      label: 'Nombre del colaborador',
      width: 200, thClass: 'pr-3',
      tdClass: 'pr-3'
    },
    {
      id: 'payrollNumber',
      label: 'Nómina',
      width: 150, thClass: 'px-3',
      tdClass: 'px-3 border-left'
    },
    {
      id: 'createdAt',
      label: 'Invitado el',
      width: 120, thClass: 'px-3',
      tdClass: 'px-3 border-left'
    },
    // {
    //   id: 'dateAdmission',
    //   label: 'Ingresó a la empresa el',
    //   width: 200, thClass: 'px-3',
    //   tdClass: 'px-3 border-left'
    // },
    {
      id: 'email',
      label: 'Email',
      width: 120, thClass: 'px-3',
      tdClass: 'px-3 border-left'
    },
    {
      id: 'actions',
      label: '',
      width: 120, thClass: 'pl-3',
      tdClass: 'pl-3 border-left'
    },
  ];
  invitations = [];

  columns = [
    {
      id: 'name',
      label: 'Nombre del colaborador',
      width: 200, thClass: 'pr-3',
      tdClass: 'pr-3'
    },
    {
      id: 'payrollNumber',
      label: 'Nómina',
      width: 150, thClass: 'px-3',
      tdClass: 'px-3 border-left'
    },
    // {
    //   id: 'timeLeft',
    //   label: 'Antigüedad',
    //   width: 120, thClass: 'px-3',
    //   tdClass: 'px-3 border-left'
    // },
    {
      id: 'registrationDate',
      label: 'Dado de alta el',
      width: 200, thClass: 'px-3',
      tdClass: 'px-3 border-left'
    },
    {
      id: 'email',
      label: 'Email',
      width: 120, thClass: 'px-3',
      tdClass: 'px-3 border-left'
    },
    {
      id: 'actions',
      label: '',
      width: 120, thClass: 'pl-3',
      tdClass: 'pl-3 border-left'
    },
  ];
  users = [];

  certificateCategories = [];

  campuses = [];

  showDropdownMenu = false;

  constructor(
    private apiService: ApiService,
    private dialog: MatDialog,
    private _user: UserApi,
    private _groupEmployee: Group_employeeApi,
    private _categoryApi: CertificateCategoryApi,
    private _campusApi: CampusApi,
  ) { }

  async ngOnInit() {
    this.loading = true;
    let [invitations, users] = await Promise.all([
      this.apiService.apiSynchronize('usersPending'),
      this.getUsersCompany()
    ]);
    this.loading = false;
    this.invitations = invitations || [];
    this.invitations.forEach((user) => {
      user.name = user.deletionRequest ? user.name + ' (Pendiente de dar de baja)' : user.name;
    });

    this.users = users || [];
    this.users = this.users.filter(user => !user.isDeleted);
    this.users.forEach((user) => {
      user.name = user.deletionRequest ? user.name + ' (Pendiente de dar de baja)' : user.name;
    });

    const categories = await this._categoryApi.find({ where: { companyId: this.apiService.company.id } }).toPromise();
    this.certificateCategories = categories;

    this._campusApi.find({ where: { companyId: this.apiService.company.id } }).subscribe((response) => {
      this.campuses = response
    });
  }

  async getUsersCompany() { //Para traer los usuarios de su compañía
    const users = await this._user.find({
      where: {
        and: [
          { isDeleted: false },
          { companyId: this.apiService.company.id },
          { emailVerified: true }
        ]
      },
      include: ['certs', 'gifts']
    }).toPromise();
    return users;
  }

  async resendAll() {
    this.loading = true;
    await this.apiService.apiSynchronize('resendInvitation', { companyId: this.apiService.company.id });
    this.loading = false;
  }

  resend(user) {
    this.openDialog(ForwardInvitationDialogComponent, user);
  }

  userEmail(user) {
    this.openDialog(UserEmailDialogComponent, user);
  }

  delete(user) {
    let dialog = this.dialog.open(UserDeleteDialogComponent, {
      data: user
    });
    dialog.afterClosed().subscribe(resp => {
      this.ngOnInit();
    });
  }

  newUser() {
    let dialog = this.dialog.open(NewUserDialogComponent);
    dialog.afterClosed().subscribe(resp => {
      this.ngOnInit();
    });
  }

  async userDialog(user) {
    const userGroups = await this._groupEmployee.find({
      where: {
        userId: user.id
      },
      include: ['group']
    }).toPromise();
    console.log({ userGroups })
    user.groupEmployees = userGroups;
    this.openDialog(UserDialogComponent, user);
  }

  userDates(user) {
    this.openDialog(UserDatesDialogComponent, user);
  }

  openDialog(Dialog, data?: Object, afterClosed?) {
    let dialog = this.dialog.open(Dialog, {
      ...Dialog.box,
      data
    })
    if (afterClosed) {
      dialog.afterClosed().subscribe(afterClosed);
    }
  }

  processData(user, col) {
    switch (col.id) {
      case 'lastDate': {
        if (!user.schedule[0]) return 'Sin envíos';
        return moment(user.schedule[0].date).format('L');
      }
      // case 'dateAdmission': {
      //   if(!user.dateAdmission) return 'Fecha Pendiente'
      //   return moment(user.dateAdmission).format('L');
      // }
      case 'createdAt': {
        return moment(user[col.id]).format('L');
      }
      // case 'timeLeft': {
      //   let date = moment(user.dateAdmission);
      //   let today = moment();
      //   let years = today.diff(date, 'years');
      //   if (!years) return today.diff(date, 'months') + ' meses';
      //   return years + ' años';
      // }
      case 'registrationDate': {
        return moment(user.createdAt).format('L');
      }
      case 'payrollNumber': {
        if (!user.payrollNumber) return 'Pendiente'
      }
      default: {
        return user[col.id];
      }
    }
  }

  reporteColaboradoresCategoria(categoryId) {
    let data: any = [
      {
        title: 'Reporte de todos los colaboradores',
        fecha: 'Fecha: ' + moment(new Date()).format('DD/MM/YYYY')
      },
      {
        payrollnumber: 'NOMINA',
        name: 'NOMBRE',
        email: 'EMAIL',
        lealtad: 'LEALTAD/ANTIGÜEDAD',
        monto: 'MONTO ASIGNADO',
        empresa: 'EMPRESA',
        campus: 'CAMPUS'
      }
    ];
    this.users.filter(user => user.certs.find(cer => cer.categoryId == categoryId)).forEach(element => {
      if (element.dateAdmission) {
        var ant: any = moment().diff(element.dateAdmission, 'weeks');
        if (ant >= 52) {
          ant = moment().diff(element.dateAdmission, 'years');
          if (ant == 1) {
            ant = ant + " año";
          }
          else {
            ant = ant + " años";
          }
        }
        else {
          ant = ant + " semanas";
        }
      }

      let companyName = this.apiService.company.name;

      if (element.isTec) companyName = 'Tecnológico de Monterrey';
      if (element.isSorteosTec) companyName = 'Sorteos TEC';
      if (element.isTecMilenio) companyName = 'Tecmilenio';
      if (element.isTecSalud) companyName = 'Tec Salud';
      if (element.isTecSec) companyName = 'Secundarias';

      const certLealtad = element.certs.find(cer => cer.categoryId == categoryId);
      const nameCampus = this.campuses.find((camp: any) => camp.id == element.campusId)
      element.amount = certLealtad ? certLealtad.valuehistory : 0;
      let user = {
        payrollnumber: `${element.payrollNumber || 'No asignado'}`,
        name: element.name,
        email: element.email,
        lealtad: ant ? ant : element.years,
        monto: element.amount,
        empresa: companyName,
        campus: nameCampus ? nameCampus.name : '-',
      }
      data.push(user);
    });
    const archivo = new Angular5Csv(data, 'ReporteColaboradores-' + moment(new Date()).format('DD/MM/YYYY,h:mm a'));
  }

}
