import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { QuestionApi } from 'src/app/services/shared/sdk';
import { UserDeleteDialogComponent } from '../..';

@Component({
  selector: 'app-question-delete',
  templateUrl: './question-delete.component.html',
  styleUrls: ['./question-delete.component.scss']
})
export class QuestionDeleteComponent {
  loading = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) private question,
    private dialog: MatDialogRef<UserDeleteDialogComponent>,
    private questionApi: QuestionApi
  ) {
  }

  async delete() {
    this.loading = true;
    const deleted = await this.questionApi.patchAttributes(this.question.id, { isDeleted: true }).toPromise()
    // const deleted = await this.questionApi.deleteById(this.question.id).toPromise() // Hard Delete

    console.log('deleted', deleted);
    this.dialog.close(true)
  }

}
