import { Component } from '@angular/core';
import { HistoryUsersComponent } from '../history-users/history-users.component';
import { ApiService } from 'src/app/services/api.service';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-history-groups',
  templateUrl: '../history-users/history-users.component.html',
  styleUrls: ['../history-users/history-users.component.scss']
})
export class HistoryGroupsComponent extends HistoryUsersComponent {

  target = 'group';
  targetLabel = 'grupo';

  constructor(apiService: ApiService, dialog: MatDialog) {
    super(apiService, dialog);
  }

}
