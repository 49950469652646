import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { ApiService } from 'src/app/services/api.service';
import { MatDialog, MatSnackBar } from '@angular/material';
import { CancelScheduledDialogComponent } from 'src/app/components';
import { Angular5Csv } from 'angular5-csv/Angular5-csv';

moment.locale('es');

@Component({
  selector: 'app-schedule-users',
  templateUrl: './schedule-users.component.html',
  styleUrls: ['./schedule-users.component.scss']
})
export class ScheduleUsersComponent implements OnInit {
  p: number = 1;
  loading = false;
  label = 'colaboradores';
  scheduleType = 'user';
  searchByName: string;
  searchByCategory: number = null;

  columns = [
    {
      id: 'user.name',
      label: 'Nombre del colaborador',
      width: 250, thClass: 'pr-3',
      tdClass: 'pr-3'
    },
    {
      id: 'value',
      label: 'Monto',
      width: 100, thClass: 'px-3',
      tdClass: 'px-3 border-left'
    },
    {
      id: 'id',
      label: 'ID',
      width: 50, thClass: 'px-3',
      tdClass: 'px-3 border-left'
    },
    {
      id: 'createdAt',
      label: 'Creada el',
      width: 110, thClass: 'px-3',
      tdClass: 'px-3 border-left'
    },
    {
      id: 'date',
      label: 'Programada al',
      width: 180, thClass: 'px-3',
      tdClass: 'px-3 border-left'
    },
    {
      id: 'category.name',
      label: 'Categoría',
      width: 150, thClass: 'px-3',
      tdClass: 'px-3 border-left'
    },
    {
      id: 'actions',
      label: '',
      width: 95, thClass: 'px-3',
      tdClass: 'pl-3 border-left'
    },
  ];


  constructor(
    protected apiService: ApiService,
    protected dialog: MatDialog,
    protected snackBar: MatSnackBar,
  ) { }

  async ngOnInit() {

    let arr2sync = [];
    if (!this.apiService.schedule.length) arr2sync.push(this.apiService.apiSynchronize('schedule'));
    if (!this.apiService.certCategories.length) arr2sync.push(this.apiService.apiSynchronize('certCategories'));

    this.loading = true;
    let [schedule, categories] = await Promise.all(arr2sync);
    this.loading = false;


    if (schedule) {
      schedule = schedule.filter(s => !s.category.isDeleted);
      this.apiService.schedule = schedule.filter((sc: any) => sc.companyId == this.apiService.company.id).reverse();
    }
    if (categories) this.apiService.certCategories = categories;

  }

  get categories() {
    return this.apiService.certCategories;
  }

  get data() {
    return (this.apiService.schedule || []).filter(s => {
      let conditions = this.scheduleType === 'user' ? !!s.userId : !!s.groupId;
      if (!conditions) return false;
      if (this.searchByName) {
        let toSearch = this.searchByName.toLowerCase();
        let name = s[this.scheduleType].name.toLowerCase();
        conditions = name.includes(toSearch);
      }
      if (this.searchByCategory) {
        conditions = conditions && s.categoryId == this.searchByCategory;
      }
      return conditions;
    });
  }

  processData(line, col) {
    switch (col.id) {
      case 'createdAt': {
        return moment(line[col.id]).format('L');
      }
      case 'date': {
        return moment(line[col.id]).format('L - hh:mm a');
      }
      case 'user.name': {
        return line.user.name;
      }
      case 'group.name': {
        return line.group.name;
      }
      case 'category.name': {
        return line.category.name;
      }
      default: {
        return line[col.id];
      }
    }
  }

  cancel(line) {
    const dialogRef = this.dialog.open(CancelScheduledDialogComponent, {
      ...CancelScheduledDialogComponent.box,
      data: line
    })

    dialogRef.afterClosed().subscribe(deleted => {
      if (deleted) {
        const categories = this.categories as any;
        const category = categories.find(cat => cat.id == line.certificateCategoryId);
        category.balance += line.value;
        this.apiService.setCertCategoriesBalance(categories);
        this.snackBar.open('Envío cancelado.', 'Hecho', { duration: 3000 });
      }
    })
  }

  edit(line) {

  }

  async download() {
    this.loading = true;
    let res = await this.apiService.apiSynchronize('scheduleReport');
    this.loading = false;
  }

  async descargar() {
    let schedules = this.apiService.schedule;
    if (this.searchByCategory) {
      schedules = this.apiService.schedule.filter(sc => sc.categoryId == this.searchByCategory)
    }
    let data: any = [{
      title: 'Reporte de Envíos Programados',
      fecha: 'Fecha: ' + moment(new Date()).format('DD/MMMM/YYYY hh:mm a')
    },
    {
      nombre: 'Nombre',
      correo: 'Correo',
      fecha: 'Fecha de Pedido',
      categoria: 'Categoría',
      monto: 'Monto'
    }];
    schedules.forEach(schedule => {
      data.push({
        nombre: schedule.user.name,
        correo: schedule.user.email,
        fecha: moment.utc(schedule.date).format('DD/MMMM/YYYY'),
        categoria: schedule.category.name,
        monto: schedule.value
      });
    });
    const archivo = new Angular5Csv(data, 'Reporte-de-Envios-Programados_' + moment().format("DD/MM/YYYY-hh:mm a"));
  }

}
