import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

  tabTitle = 'Colaboradores y grupos';
  navLinks = [{
    path: ['colaboradores'],
    label: 'Colaboradores',
    icon: 'person'
  },
  {
    path: ['grupos'],
    label: 'Grupos',
    icon: 'people'
  }];

  constructor() { }

  ngOnInit() {
  }

}
