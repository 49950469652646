import { EventEmitter, Injectable } from '@angular/core';
import { MatDialog, MatSnackBar } from "@angular/material";
import { Router } from '@angular/router';
import * as buildUrl from "build-url";
import * as moment from 'moment';
import { environment } from 'src/environments/environment';
// no usar "../components" aquí porque salen warnings de error de redundancia ciclica
import { ErrorDialogComponent } from '../components/dialogs/error-dialog/error-dialog.component';
import { isDefined } from '@angular/compiler/src/util';
import { CertificateCategory, CertificateCategoryInterface, LoopBackAuth, Question } from './shared/sdk';

const method = (m) => {
  return !environment.local ? m : 'GET';
}

interface DateSchedule {
  day: number;
  month: number;
}

interface User {
  id: number;
  name: string;
  email: string;
  birthdate: Date;
  maritalStatus: 'married' | 'single';
  anniversary: DateSchedule;
  birthdateCouple: DateSchedule;
  parentType: string | boolean;
  childrenBirthdates: DateSchedule[];
  dateAdmission: string;
  payrollNumber: string;
  emailVerified: boolean;
  registered: boolean;
  balance: number;
  type: string;
}

interface ShortUserOrGroup {
  id: number;
  name: string;
  email: string;
  users?: {
    id: number;
    name: string;
    email: string;
  }[];
  createdAt?: Date;
}

interface Store {
  id: number;
  name: string;
  description: string;
  logo: string;
  img: string;
  terms: string;
  values: number[];
  active?: boolean;
}

interface Cert {
  id: number;
  userId: number;
  user?: User;
  img: string;
  title: string;
  description: string;
  message?: string;
  value: number;
  total: number;
  expirationDate: Date;
  categoryId: number;
  sliderActive?: boolean;
  collapseOpen?: boolean;
  status?: string;
  modify?: { status: string, date: Date }[];
  category?: CertCategory;
  createdAt: Date;
  scheduleId: number;
  schedule?: CertSchedule;
  history: { status: string, date: Date }[];
}

interface Gift {
  id: number;
  value: number;
  date: string;
  userId: number;
  storeId: number;
  status?: 'process' | 'available' | 'expired' | 'changed';
  store?: Store;
  rows: { cert: Cert, value: number }[];
}

// se requiere para la categorías de certificados, necesita una fecha o un identificador
interface ImportantDate {
  title: string;
  date: DateSchedule | 'birthdate' | 'anniversary' | 'birthdateCouple' | 'dateAdmission' | 'childBirthdate' | Date;
  left?: number;
}

// Únicamente sirve para ahorrar tiempo de escribir a Empresa?
interface CertTemplate {
  id: number;
  name: string;
  description: string;
}

// Tiene una descripción donde se coloca "Tipos de diseño"
interface CertCategory extends Omit<CertificateCategoryInterface, 'dateDefault'> {
  id: number;
  name: string;
  images: string[];
  description: string;
  dateDefault: DateSchedule | 'birthdate' | 'anniversary' | 'birthdateCouple' | 'dateAdmission' | 'childBirthdate' | Date;
  active: boolean;
  selected?: boolean;
  desArray?: string;
}

//programación de certificados
interface CertSchedule {
  id: number;
  userId?: number;
  user?: User;
  groupId?: number;
  group?: Group;
  img: string;
  createdAt?: Date;
  date: Date;
  value: number;
  certificateCategoryId: number;
  categoryId: number;
  category?: CertCategory;
  description: string;
}

interface Group {
  id: number;
  name: string;
  users: (User | number)[];
  createdAt: Date;
  schedule: CertSchedule[];
  isDeleted: boolean;
}

interface Company {
  id: number;
  name: String;
  balance: number;//saldo
  //value
  total: number;
  users?: ShortUserOrGroup[];
  groups?: Group[];
  certs?: Cert[];
  schedule?: CertSchedule[];
}

interface Campus {
  id: number;
  name: string;
  companyId: number;
  isDeleted: boolean;
}

interface Faqs {
  id: number;
  question: string;
  answer: string;
}

interface Settings {
  name: string;
  logo: string;
  phone: string;
  office: string;
  workingHours: string;
  email: string;
  facebook: string;
  linkedin: string;
  instagram: string;
}

interface Events {
  date: Date;
  place: string;
  campusId: number;
  startTime: string;
  companyId: number;
  isDeleted: boolean;
  active: boolean;
}

interface CategoriesBalance {
  total: number;
  categories: CertCategory[] | CertificateCategoryInterface[];
}

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  _userId: number;
  _user: User;
  _token: string | boolean = false;
  _certs: Cert[] = [];
  _certActive: Cert;
  _gifts: Gift[] = [];
  _giftsArchive: any[] = [];
  _dates: ImportantDate[] = [];
  _stores: Store[] = [];
  _certCategories: CertCategory[] = [];
  _certTemplates: CertTemplate[] = [];
  _users: ShortUserOrGroup[] = [];
  _groups: Group[] = [];
  _company: Company;
  _schedule: CertSchedule[] = [];
  _faqs: Faqs[] = [];
  _settings: Settings = <Settings>{};
  _certsArchive: Cert[] = [];
  _notifications: ImportantDate[] = [];
  _events: Events[] = [];
  _campuses: Campus[] = [];

  terms: string | boolean = false;
  privacy: string | boolean = false;

  _categoriesBalance: CategoriesBalance;

  editQuestion: EventEmitter<Question> = new EventEmitter<Question>()
  deleteQuestion: EventEmitter<Question> = new EventEmitter<Question>()

  onUpdateCategoriesBalance: EventEmitter<CategoriesBalance> = new EventEmitter<CategoriesBalance>();

  constructor(
    private dialog: MatDialog,
    private router: Router,
    public snackBar: MatSnackBar,
    private auth: LoopBackAuth
  ) {

  }

  bucket(hash) {
    if (hash != "https://adminngift.s3-us-west-2.amazonaws.com/assets/images/welcome/bienvenido.png") {
      return 'https://apin.giftpedia.com.mx/api/' + 'containers/cloudgiftmaster/download/' + hash;
    }
    else {
      return hash;
    }
  }

  set balance(value) {
    this._user.balance = value;
  }

  get balance() {
    return this.user.balance || 0;
  }

  set users(users) {
    this._users = users.map(u => ({
      id: u.id,
      name: u.name,
      email: u.email
    }));
  }

  get users() {
    return this._users || [];
  }

  set groups(groups) {
    this._groups = groups.map(g => {
      return {
        id: g.id,
        name: g.name,
        users: g.users || [],
        createdAt: g.createdAt,
        schedule: g.schedule || [],
        isDeleted: g.isDeleted
      }
    });
  }

  get groups() {
    return this._groups || [];
  }

  set campuses(campuses) {
    this._campuses = campuses.map((campus) => {
      return {
        id: campus.id,
        name: campus.name,
        companyId: campus.companyId,
        isDeleted: campus.isDeleted
      }
    });
  }

  get campuses() {
    return this._campuses;
  }

  groupById(id) {
    return this._groups.find(g => g.id === parseInt(id));
  }

  certsProcess(certs: Cert[]): Cert[] {
    return certs.map(c => ({
      id: c.id,
      userId: c.userId,
      user: c.user,
      img: this.bucket(c.img),
      title: c.title,
      description: c.message,
      value: c.value,
      expirationDate: c.expirationDate ? new Date(c.expirationDate) : null,
      total: c.total || c.value,
      category: c.category || <CertCategory>{},
      status: c.status,
      categoryId: c.categoryId,
      createdAt: c.createdAt ? new Date(c.createdAt) : null,
      scheduleId: c.scheduleId,
      history: c.history
    }));
  }

  set certs(certs) {
    this._certs = this.certsProcess(certs);
    this.certActive = this._certs[0];
  }

  get certs() {
    return this._certs || [];
  }

  set certsArchive(certs) {
    this._certsArchive = this.certsProcess(certs);
  }

  get certsArchive() {
    return this._certsArchive;
  }

  set certActive(cert) {
    // el cetificado activo no debe coincidir precisamente con la bandera active, ya que active se usa para el scroll
    this._certActive = cert;
  }

  get certActive() {
    return this._certActive;
  }

  set events(event) {
    this._events = event.map((e) => ({
      date: e.date,
      place: e.place,
      campusId: e.campusId,
      startTime: e.startTime,
      companyId: e.companyId,
      isDeleted: e.isDeleted,
      active: e.active
    }));
  }

  get events() {
    return this._events;
  }

  set certCategories(certCategories) {
    this._certCategories = certCategories.map(cc => ({
      id: cc.id,
      name: cc.name,
      images: cc.images ? cc.images.map(img => (img)) : [],
      description: cc.description,
      dateDefault: cc.dateDefault,
      active: false,
      selected: false,
      desArray: cc.desArray,
      balance: cc.balance
    }));
  }

  get certCategories() {
    return this._certCategories;
  }

  setCertCategoriesBalance(categories: CertificateCategoryInterface[]) {
    this._categoriesBalance = {
      total: categories.reduce<number>((prev, current: CertificateCategoryInterface): number => prev = prev + current.balance, 0),
      categories
    }
    this.onUpdateCategoriesBalance.emit(this._categoriesBalance);
  }

  get certCategoriesBalance() {
    return this._categoriesBalance;
  }

  set certTemplates(certTemplates) {
    this._certTemplates = certTemplates.map(ct => ({
      id: ct.id,
      name: ct.name,
      description: ct.description
    }));
  }

  get certTemplates() {
    return this._certTemplates;
  }

  set stores(stores: any[]) {
    this._stores = stores.map(s => ({
      id: s.id,
      name: s.name,
      description: s.description,
      logo: this.bucket(s.logo),
      img: this.bucket(s.giftDesign),
      terms: s.terms,
      values: s.values,
      active: false
    }));
  }

  get stores() {
    return this._stores;
  }

  getStoreById(storeId) {
    let store = this._stores.find(s => s.id === storeId);
    return store;
  }

  storeSetActive(storeId) {
    this.getStoreById(storeId).active = true;
  }

  removeGift(giftId) {
    let giftIndex = this.gifts.findIndex(g => g.id === giftId);
    this.gifts.splice(giftIndex, 1);
    this.gifts.forEach((g, i) => {
      g.id = (i + 1);
    })
  }

  setGift(gift) {
    gift.id = this._gifts.length + 1;
    this._gifts.push(gift);
  }

  findCert(certId) {
    return this._certs.find(c => c.id === parseInt(certId));
  }

  get certsCheckoutLength() {
    return 0;
  }

  set giftsArchive(gifts: any[]) {
    this._giftsArchive = gifts.map(g => ({
      id: g.id,
      value: g.value,
      date: g.createdAt,
      userId: g.userId,
      certId: g.certificateId,
      storeId: g.storeId,
      status: g.status,
      store: {
        ...g.store,
        img: this.bucket(g.store.giftDesign),
        logo: this.bucket(g.store.logo)
      },
      certs: g.certificates,
      categories: g.categories
    }));
  }

  get giftsArchive() {
    return this._giftsArchive;
  }

  get gifts() {
    return this._gifts;
  }

  get isRegistered() {
    return this._user.registered;
  }

  get token() {
    if (!this._token) {
      let token = localStorage.getItem('token') || sessionStorage.getItem('token');
      this._userId = parseInt(localStorage.getItem('userId') || sessionStorage.getItem('userId'));
      this._token = token;
    }
    return this._token;
  }

  set certsSliderIndex(n: number) {
    this._certActive = this.certs[n];
  }

  get certsSliderIndex(): number {
    if (!this._certActive) return null;
    return this.certs.findIndex(c => c.id === this._certActive.id);
  }

  get dates() {
    return this._dates;
  }

  set company(c) {
    if (!c) return;
    this._company = {
      id: c.id,
      name: c.name,
      balance: c.balance,
      total: c.total
    };
    this._users = c.users;
    this._groups = c.groups;
    this._certs = c.certs;
    this._schedule = c.schedule;
  }

  get company() {
    return this._company;
  }

  report(form) {
    return this.apiSynchronize('report', {
      companyId: this.company.id,
      senderId: this._userId,
      code: form.category,
      description: form.description,
      extraData: form.data,
      createdAt: new Date(),
      type: 'other',
      status: 'activo'
    });
  }

  openErrorDialog(e) {
    this.dialog.open(ErrorDialogComponent, {
      ...ErrorDialogComponent.box,
      data: e
    });
  }

  apiSynchronize(type, data?: any) {

    const PATCH = method('PATCH');
    const POST = method('POST');
    const DELETE = method('DELETE');

    const QUERY_TOKEN = {
      access_token: <string>this.token
    };
    const HEADERS = {
      accepts: 'application/json',
      'content-type': 'application/json'
    };
    const BODY = !environment.local && data ? JSON.stringify(data) : undefined;

    const buildApiUrl = (params) => {
      return buildUrl(environment.apiUrl, Object.assign({
        path: '',
        queryParams: QUERY_TOKEN
      }, params));
    }

    const tryResponse = async (r) => {
      try {
        var payload = await r.json();
      } catch (error) {
        // si el error es SyntaxError quiere decir que el servidor a mandado una respuesta vacía
        if (error.name != 'SyntaxError')
          console.error(type + ':', error);
        payload = r.ok ?
          { done: true } :
          { error: { name: "Error", message: "Error parsing json response" } }
      }
      return payload;
    };


    let promise = Promise.resolve(new Response());
    switch (type) {
      case 'login': {
        let url = buildApiUrl({
          path: '/users/login',
          queryParams: {
            include: 'user'
          }
        });
        promise = promise.then(() => fetch(url, {
          method: POST,
          headers: HEADERS,
          body: BODY
        }));
        break;
      }
      case 'logout': {
        let url = buildApiUrl({ path: '/users/logout' });
        promise = promise.then(() => fetch(url, {
          method: POST,
          headers: HEADERS
        }));
        break;
      }
      case 'user': {
        let url = buildApiUrl({
          path: ('/users/' + this._userId) + (environment.local ? '/index' : ''),
          queryParams: {
            ...QUERY_TOKEN,
            filter: JSON.stringify({
              include: ['company']
            })
          }
        });
        promise = promise.then(() => fetch(url));
        break;
      }
      case 'users': {
        let url = buildApiUrl({
          path: '/users' + (environment.local ? '/index' : ''),
          queryParams: {
            ...QUERY_TOKEN,
            filter: JSON.stringify({
              where: { companyId: this._company.id, isDeleted: false },
              fields: ['id', 'name', 'email', 'companyId']
            })
          }
        });
        promise = promise.then(() => fetch(url));
        break;
      }
      case 'deleteUser': {
        let url = buildApiUrl({ path: '/users/' + data.id });

        promise = promise.then(() => fetch(url, {
          method: PATCH,
          headers: HEADERS,
          body: BODY
        }));
        break;
      }
      case 'newUser': {
        let url = buildApiUrl({ path: '/users' });
        promise = promise.then(() => fetch(url, {
          method: POST,
          headers: HEADERS,
          body: BODY
        }));
        break;
      }
      case 'sendNewEmail': {
        let url = buildApiUrl({ path: '/users/' + data.id });
        promise = promise.then(() => fetch(url, {
          method: PATCH,
          headers: HEADERS,
          body: BODY
        }));
        break;
      }
      case 'requestNewcategory': {
        let url = buildApiUrl({ path: '/users/requestNewcategory' });
        promise = promise.then(() => fetch(url, {
          method: POST,
          headers: HEADERS,
          body: BODY
        }));
        break;
      }
      case 'deletionRequest': {
        let url = buildApiUrl({ path: '/users/deletionRequest' });
        promise = promise.then(() => fetch(url, {
          method: POST,
          headers: HEADERS,
          body: BODY
        }));
        break;
      }
      case 'resendInvitation': {
        let url = buildApiUrl({ path: '/users/resendInvitation' });
        promise = promise.then(() => fetch(url, {
          method: POST,
          headers: HEADERS,
          body: BODY
        }));
        break;
      }
      case 'events': {
        let url = buildApiUrl({
          path: '/Events',
          queryParams: {
            ...QUERY_TOKEN,
            filter: JSON.stringify({
              where: { companyId: this._company.id },
              include: ['campus']
            })
          }
        });
        promise = promise.then(() => fetch(url));
        break;
      }
      case 'campuses': {
        let url = buildApiUrl({
          path: '/Campuses',
          queryParams: {
            ...QUERY_TOKEN,
            filter: JSON.stringify({
              where: { companyId: this._company.id, isDeleted: false }
            })
          }
        });
        promise = promise.then(() => fetch(url));
        break;
      }
      case 'requestNewEvent': {
        let url = buildApiUrl({ path: '/users/requestNewEvent' });
        promise = promise.then(() => fetch(url, {
          method: POST,
          headers: HEADERS,
          body: BODY
        }));
        break;
      }
      case 'usersList': {
        let url = buildApiUrl({
          path: '/users' + (environment.local ? '/index' : ''),
          queryParams: {
            ...QUERY_TOKEN,
            filter: JSON.stringify({
              where: { companyId: this._company.id, isDeleted: false, emailVerified: true },
              include: [{
                relation: 'groupEmployees',
                scope: {
                  include: ['group']
                }
              }]
            })
          }
        });
        promise = promise.then(() => fetch(url));
        break;
      }
      case 'usersPending': {
        let url = buildApiUrl({
          path: '/users' + (environment.local ? '/index' : ''),
          queryParams: {
            ...QUERY_TOKEN,
            filter: JSON.stringify({
              where: {
                companyId: this._company.id,
                emailVerified: null,
                isDeleted: false
              },
              fields: ['id', 'name', 'createdAt', 'payrollNumber', 'dateAdmission', 'email', 'deletionRequest']
            })
          }
        });
        promise = promise.then(() => fetch(url));
        break;
      }
      case 'usersSearch': {
        let url = buildApiUrl({
          path: '/users' + (environment.local ? '/index' : ''),
          queryParams: {
            ...QUERY_TOKEN,
            filter: JSON.stringify({
              where: {
                companyId: this._company.id,
                email: data.search ? { ilike: '%' + data.search + '%' } : undefined
              },
              limit: 5,
              fields: ['id', 'name', 'email']
            })
          }
        });
        promise = promise.then(() => fetch(url));
        break;
      }
      case 'groupNames': {
        let url = buildApiUrl({
          path: '/Groups' + (environment.local ? '/index' : ''),
          queryParams: {
            ...QUERY_TOKEN,
            filter: JSON.stringify({
              where: { companyId: this._company.id },
              fields: ['id', 'name', 'createdAt'],
              include: [{
                relation: 'users', scope: {
                  fields: ['id', 'name', 'isDeleted']
                }
              }]
            })
          }
        });
        promise = promise.then(() => fetch(url));
        break;
      }
      case 'groups': {
        let url = buildApiUrl({
          path: '/Groups' + (environment.local ? '/index' : ''),
          queryParams: {
            ...QUERY_TOKEN,
            filter: JSON.stringify({
              where: { companyId: this._company.id },
              fields: ['id', 'name', 'createdAt', 'isDeleted'],
              include: [{
                relation: 'users', scope: {
                  fields: ['id', 'groupId', 'name', 'isDeleted', 'email']
                }
              }, 'schedules']
            })
          }
        });
        promise = promise.then(() => fetch(url));
        break;
      }
      case 'sendNewGroup': {
        let url = buildApiUrl({ path: '/Groups/newGroup' });
        promise = promise.then(() => fetch(url, {
          method: POST,
          headers: HEADERS,
          body: BODY
        }));
        break;
      }
      case 'editGroup': {
        let url = buildApiUrl({ path: '/Groups/' + data.id });
        promise = promise.then(() => fetch(url, {
          method: PATCH,
          headers: HEADERS,
          body: BODY
        }));
        break;
      }
      case 'deleteGroup': {
        let url = buildApiUrl({ path: '/Groups/' + data.id });
        promise = promise.then(() => fetch(url, {
          method: DELETE,
          headers: HEADERS
        }));
        break;
      }
      case 'settings': {
        let url = buildApiUrl({ path: '/Settings' });
        promise = promise.then(() => fetch(url));
        break;
      }
      case 'company': {
        let url = buildApiUrl({
          path: ('/Companies/' + this.user.companyId) + (environment.local ? '/index' : '')
        });
        promise = promise.then(() => fetch(url));
        break;
      }
      case 'register': {
        let url = buildApiUrl({ path: '/users/reset-password' });
        promise = promise.then(() => fetch(url, {
          method: POST,
          headers: HEADERS,
          body: (!environment.local ?
            JSON.stringify({ newPassword: data.newPassword }) : undefined)
        }));
      }
      case 'userUpdate': {
        let url = buildApiUrl({ path: ('/users/' + this._user.id) + (environment.local ? '/index' : '') });
        data.password = undefined;
        delete data.password;
        promise = promise.then(() => fetch(url, {
          method: PATCH,
          headers: HEADERS,
          body: (!environment.local ?
            JSON.stringify(data) : undefined)
        }));
        break;
      }
      case 'passwordUpdate': {
        let url = buildApiUrl({ path: '/users/change-password' });
        promise = promise.then(() => fetch(url, {
          method: POST,
          headers: HEADERS,
          body: BODY
        }));
        break;
      }
      case 'gifts': {
        let url = buildApiUrl({
          path: '/Gifts',
          queryParams: {
            ...QUERY_TOKEN,
            filter: JSON.stringify({
              // TODO, podría optimizarce la consulta del comercio si se busca
              // en el arreglo de _stores si no está, pedirlo del serivodor y agregarlo al arreglo
              include: ['store', {
                relation: 'categories',
                scope: {
                  fields: ['id', 'name']
                }
              }]
            })
          }
        });
        let data = fetch(url);
        promise = promise.then(() => data);
        break;
      }
      case 'report': {
        let url = buildApiUrl({ path: '/Tickets' });
        promise = promise.then(() => fetch(url, {
          method: POST,
          headers: HEADERS,
          body: BODY
        }));
        break;
      }
      case 'stores': {
        let url = buildApiUrl({ path: '/Stores' });
        promise = promise.then(() => fetch(url));
        break;
      }
      case 'certs': {
        let url = buildApiUrl({
          path: '/Certificates' + (environment.local ? '/index' : ''),
          queryParams: {
            ...QUERY_TOKEN,
            filter: JSON.stringify({
              where: {
                companyId: this.company.id,
                isWelcome: null
              },
              include: [{
                relation: 'user',
                scope: { fields: ['id', 'name', 'email'] }
              },
              {
                relation: 'category',
                scope: { fields: ['id', 'name'] }
              }]
            })
          }
        });
        promise = promise.then(() => fetch(url));
        break;
      }
      case 'downloadCert': {
        let url = buildApiUrl({ path: '/Certificates/' + data.certId + '/download' });
        promise = promise.then(() => window.open(url, '_blank'))
          .then(() => new Response(JSON.stringify({ done: true })));
        break;
      }
      case 'downloadCerts': {
        let url = buildApiUrl({ path: '/Certificates/download' });
        promise = promise.then(() => window.open(url, '_blank'))
          .then(() => new Response(JSON.stringify({ done: true })));
        break;
      }
      case 'sendNewGifts': {
        let url = buildApiUrl({ path: '/Gifts' });
        promise = promise.then(() => fetch(url, {
          method: POST,
          headers: HEADERS,
          body: BODY
        }))
          .then(async (r) => {
            let payload = await tryResponse(r);
            if (payload && payload.error) throw payload.error;
            this.balance -= this._gifts.reduce((t, g) => (t + g.value), 0);
            this._gifts = [];
            return payload;
          });
        break;
      }
      case 'editSchedule': {
        let url = buildApiUrl({ path: '/CertSchedules/' + data.id });
        promise = promise.then(() => fetch(url, {
          method: PATCH,
          headers: HEADERS,
          body: BODY
        }));
        break;
      }
      case 'cancelSchedule': {
        let url = buildApiUrl({ path: '/CertSchedules/' + data.id });
        promise = promise.then(() => fetch(url, {
          method: DELETE,
          headers: HEADERS
        }));
        break;
      }
      //TODO Revisar esta funcion con Liz
      case 'deleteSchedule': {
        let url = buildApiUrl({ path: '/CertSchedules/deleteSchedule' });
        promise = promise.then(() => fetch(url, {
          method: POST,
          headers: HEADERS,
          body: BODY
        }));
        break;
      }

      case 'scheduleReport': {
        let url = buildApiUrl({ path: '/certSchedules/report' });
        promise = promise.then(() => window.open(url, '_blank'))
          .then(() => new Response(JSON.stringify({ done: true })));
        break;
      }
      case 'sendSchedule': {
        let url = buildApiUrl({ path: '/CertSchedules' });
        promise = promise.then(() => fetch(url, {
          method: POST,
          headers: HEADERS,
          body: BODY
        }));
        break;
      }
      case 'schedule': {
        let url = buildApiUrl({
          path: '/CertSchedules',
          queryParams: {
            ...QUERY_TOKEN,
            filter: JSON.stringify({
              include: [{
                relation: 'user',
                scope: {
                  fields: ['id', 'name', 'username', 'email']
                }
              },
              {
                relation: 'category',
                scope: {
                  fields: ['id', 'name', 'isDeleted']
                }
              },
              {
                relation: 'group',
                scope: {
                  fields: ['id', 'name']
                }
              }]
            })
          }
        });
        promise = promise.then(() => fetch(url));
        break;
      }
      case 'currentDate': {
        let url = buildApiUrl({ path: '/currentDate' });
        promise = promise.then(() => fetch(url));
        break;
      }
      case 'resetPass': {
        let url = buildApiUrl({ path: '/users/reset' });
        promise = promise.then(() => fetch(url, {
          method: POST,
          headers: HEADERS,
          body: BODY
        }));
        break;
      }
      case 'changeCert': {
        let url = buildApiUrl({ path: '/users/' + this._user.id + '/changeCertificate' });
        promise = promise.then(() => fetch(url, {
          method: POST,
          headers: HEADERS,
          body: BODY
        }));
        break;
      }
      case 'certsInBalance': {
        let url = buildApiUrl({
          path: '/Certificates' + (environment.local ? '/index' : ''),
          queryParams: {
            ...QUERY_TOKEN,
            filter: JSON.stringify({
              where: {
                status: 'inBalance'
              },
              include: [{
                relation: 'category',
                scope: {
                  fields: ['id', 'name']
                }
              }]
            })
          }
        });
        promise = promise.then(() => fetch(url));
        break;
      }
      case 'certsAvailable': {
        let url = buildApiUrl({
          path: '/Certificates' + (environment.local ? '/index' : ''),
          queryParams: {
            ...QUERY_TOKEN,
            filter: JSON.stringify({
              where: {
                status: 'available',
                userId: this.user.id
              }
            })
          }
        });
        promise = promise.then(() => fetch(url));
        break;
      }
      case 'faqs': {
        let url = buildApiUrl({ path: '/Faqs' });
        promise = promise.then(() => fetch(url));
        break;
      }
      case 'certCategories': {
        let url = buildApiUrl({
          path: '/CertificateCategories',
          queryParams: {
            ...QUERY_TOKEN,
            filter: JSON.stringify({
              where: {
                companyId: this.company.id,
                isDeleted: false
              },
              include: 'certificateDesigns'
            })
          }
        });
        promise = promise.then(() => fetch(url));
        break;
      }
      case 'certTemplates': {
        let url = buildApiUrl({
          path: '/CertTemplates',
          queryParams: {
            ...QUERY_TOKEN,
            filter: JSON.stringify({
              where: {
                companyId: this.company.id
              }
            })
          }
        });
        promise = promise.then(() => fetch(url));
        break;
      }
      case 'notifications': {
        let month = moment().month();
        let year = moment().month();
        let gDate = moment({ day: 1, month }).toDate();
        let lDate = moment({
          day: 1,
          month: month === 11 ? 0 : month + 1,
          year: month === 11 ? year + 1 : year
        }).toDate();

        let url = buildApiUrl({
          path: '/ImportantDates' + (environment.local ? '/index' : ''),
          queryParams: {
            ...QUERY_TOKEN,
            filter: JSON.stringify({
              where: {
                date: { lt: lDate, gt: gDate }
              }
            })
          }
        });
        promise = promise.then(() => fetch(url));
        break;
      }
      case 'statistics': {
        let url = buildApiUrl({ path: 'Companies/statistics' });
        promise = promise.then(() => fetch(url, {
          method: POST,
          headers: HEADERS,
          body: BODY
        }));
        break;
      }
      default: {
        promise = promise.then(() => {
          throw {
            code: "FRONT_INVALID_SYNC_OPTION",
            name: "Error: " + type,
            message: "Opción invalida para sincronizar con servidor"
          }
        });
        break;
      }
    }
    return promise
      .then(async (r) => {
        let payload: any = r;
        if (r instanceof Response)
          payload = await tryResponse(r);
        if (payload && payload.error) throw payload.error;
        return payload;
      })
      .catch(e => {
        e.service = type;
        this.processError(e);
        return false;
      });
  }

  processError(error) {
    let message;
    switch (error.code) {
      case 'LOGIN_FAILED':
        message = 'Correo electrónico o contraseña incorrecta.';
        break;
      case 'AUTHORIZATION_REQUIRED':
        message = 'No cuentas con permisos para acceder a esta información.';
        break;
      case 'INVALID_PASSWORD':
        message = 'La contraseña actual es incorrecta.';
        break;
      default:
        message = error.name || 'Error';
        console.error(error.service + ':', error);
    }
    this.snackBar.open(message, 'Ver detalles', { duration: 4000 })
      .onAction().subscribe(() => {
        this.openErrorDialog({
          title: error.name || 'Error',
          description: error.message || 'Error inesperado',
          object: error
        });
      });
  }

  get user() {
    return this._user;
  }

  set user(user: any | User) {
    if (!user) return;
    let birthdate = user.birthdate ? new Date(user.birthdate) : null;
    user.childrenBirthdates = user.childrenBirthdates ? user.childrenBirthdates : [];

    this._user = Object.assign({}, this._user, {
      id: user.id,
      name: user.name,
      email: user.email,
      birthdate,
      balance: user.balance,
      maritalStatus: user.maritalStatus,
      anniversary: user.anniversaryDate || {},
      birthdateCouple: user.birthdateCouple || {},
      parentType: user.parentType === 'false' ? false : user.parentType,
      childrenBirthdates: user.childrenBirthdates || [],
      dateAdmission: user.dateAdmission,
      payrollNumber: user.payrollNumber,
      emailVerified: user.emailVerified,
      registered: user.registered,
      companyId: user.companyId,
      type: user.type
    });

    this.company = user.company;

    // important dates from profile
    let now = moment();
    let aniv = moment(this._user.anniversary);
    let bc = moment(this._user.birthdateCouple);
    let dates = [{
      title: 'Cumpleaños',
      date: birthdate,
      left: moment(birthdate).year(now.year()).diff(now, 'days')
    },
    {
      title: 'Fecha de Ingreso',
      date: moment(user.dateAdmission).toDate(),
      left: moment(user.dateAdmission).year(now.year()).diff(now, 'days')
    }];
    if (this._user.maritalStatus === 'married') {
      dates.push({
        title: 'Aniversario',
        date: aniv.toDate(),
        left: aniv.diff(now, 'days')
      });
      dates.push({
        title: 'Cumpleaños de Pareja',
        date: bc.toDate(),
        left: bc.diff(now, 'days')
      });
    }
    dates.push(...user.childrenBirthdates.map((cbs, i) => ({
      title: 'Cumpleaños de hijo ' + (i + 1),
      date: moment(cbs),
      left: moment(cbs).diff(now, 'days')
    })));
    dates = dates.sort((a, b) => a.left - b.left).filter(d => d.left >= 0);
    this._dates = dates;
  }

  async login({ username, password, rememberme }) {
    let response = await this.apiSynchronize('login', { email: username, password });
    this.auth.setToken(response)
    if (!response) return false;
    this._token = <string>response.id;
    this.user = response.user;
    if (rememberme) {
      localStorage.setItem('token', response.id);
      localStorage.setItem('userId', response.userId);
    } else {
      sessionStorage.setItem('token', response.id);
      sessionStorage.setItem('userId', response.userId);
    }
    return this._token;
  }

  async register(form) {
    let user = {
      birthdate: moment(form.birthdate).toISOString(),
      maritalStatus: form.maritalStatus,
      anniversaryDate: form.anniversary,
      birthdateCouple: form.birthdateCouple,
      parentType: form.parentType,
      numberChildren: form.childrenBirthdates.length,
      childrenBirthdates: form.childrenBirthdates,
      newPassword: form.password,
      registered: true
    };

    let resp = await this.apiSynchronize('register', user);
    Object.assign(this.user, user);

    if (!resp) {
      return false;
    }
    this.router.navigate(['/canjear-certificados']);
    return true;
  }

  async logout() {
    await this.apiSynchronize('logout');
    this._token = false;
    localStorage.removeItem('token');
    sessionStorage.removeItem('token');
    localStorage.removeItem('userId');
    sessionStorage.removeItem('userId');
    this.router.navigate(['/acceso']);
    return true;
  }

  set faqs(faqs) {
    this._faqs = faqs.map(f => ({
      id: f.id,
      question: f.question,
      answer: f.answer
    }));
  }

  get faqs() {
    return this._faqs;
  }

  set settings(settings) {
    this._settings = {
      name: settings.name,
      logo: settings.logo,
      phone: settings.phone,
      office: settings.office,
      workingHours: settings.workingHours,
      email: settings.email,
      facebook: settings.facebook,
      linkedin: settings.linkedin,
      instagram: settings.instagram
    };
  }

  get settings() {
    return this._settings;
  }

  set schedule(schedule) {
    this._schedule = schedule.map(s => {
      s.user = <any>(s.user || {});
      s.user.name = s.user.name || (<any>s.user).username;
      s.category = <any>(s.category || {});
      s.group = <any>(s.group || {});
      return {
        id: s.id,
        userId: s.userId,
        user: s.user,
        groupId: s.groupId,
        group: s.group,
        img: s.img,
        createdAt: new Date(s.createdAt),
        date: new Date(s.date),
        value: s.value,
        certificateCategoryId: s.certificateCategoryId,
        category: s.category,
        description: s.description,
        categoryId: s.certificateCategoryId
      }
    });
  }

  get schedule() {
    return this._schedule || [];
  }

  set notifications(notifs) {
    this._notifications = notifs.map(n => ({
      title: n.title,
      date: n.date,
      left: n.left
    }));
  }

  get notifications() {
    return this._notifications;
  }
}
