import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-instructions',
  templateUrl: './instructions.component.html',
  styleUrls: ['./instructions.component.scss']
})
export class InstructionsComponent implements OnInit {


  steps = [{
    img: "assets/images/icons/step-1.svg",
    title: "Selecciona",
    description: "el certificado a canjear"
  },
  {
    img: "assets/images/icons/step-2.svg",
    title: "Revisa",
    description: "los comercios disponibles"
  },
  {
    img: "assets/images/icons/step-3.svg",
    title: "Espera",
    description: "el alta de tus regalos"
  },
  {
    img: "assets/images/icons/step-4.svg",
    title: "Disfruta",
    description: "de tus regalos"
  }];

  instructionsConf = {
    slidesPerView: 1,
    pagination: {
      el: '.swiper-pagination',
      clickable: true
    },
    observer: true,
    watchOverflow: true,
    loop: false,
    breakpoints: {
      576: {
        slidesPerView: 2,
        slidesPerColumn: 1,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        }
      },
      768: {
        slidesPerView: 2,
        slidesPerColumn: 2,
        slidesPerColumnFill: 'row',
        pagination: {
          el: '.swiper-pagination'
        }
      },
      1024: {
        slidesPerView: 4,
        slidesPerColumn: 1,
        pagination: {
          el: '.swiper-pagination'
        }
      },
    }
  };

  constructor() { }

  ngOnInit() {
  }

}
