import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import * as moment from 'moment';

@Component({
  selector: 'app-user-dates-dialog',
  templateUrl: './user-dates-dialog.component.html',
  styleUrls: ['./user-dates-dialog.component.scss']
})
export class UserDatesDialogComponent implements OnInit {

  static box = {
    width: '90%',
    maxWidth: '480px',
    maxHeight: '90vh'
  }

  importantDates = [];

  constructor(@Inject(MAT_DIALOG_DATA) private user) { }

  ngOnInit() {    
    this.importantDates = [{
      label: 'Cumpleaños',
      value: this.user.birthdate ? moment(this.user.birthdate).format('DD [de] MMM') : 'No asignado'
    },
    {
      label: 'Cumpleaños de pareja',
      value: this.user.birthdateCouple && this.user.birthdateCouple.day != '' ? moment(this.user.birthdateCouple).format('DD [de] MMM') : 'No asignado'
    },
    ...(this.user.childrenBirthdates || []).map((cb, i) => ({
      label: 'Cumpleaños de hijo ' + (i + 1),
      value: moment(cb).format('DD [de] MMM')
    })),
    {
      label: 'Aniversario de trabajo',
      value: this.user.dateAdmission ? moment(this.user.dateAdmission).format('DD [de] MMM') : 'No asignado'
    },
    {
      label: 'Aniversario de pareja',
      value: this.user.anniversaryDate && this.user.anniversaryDate.day != '' ? moment(this.user.anniversaryDate).format('DD [de] MMM') : 'No asignado'
    }];
  }

}
