import { Component, OnInit, Inject, Input } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-user-delete-dialog',
  templateUrl: './user-delete-dialog.component.html',
  styleUrls: ['./user-delete-dialog.component.scss']
})
export class UserDeleteDialogComponent implements OnInit {

  static box = {};
  loading = false;
  @Input() isGroup = false;
  agree = false;

  constructor(private apiService: ApiService, @Inject(MAT_DIALOG_DATA) private user,
    private dialog: MatDialogRef<UserDeleteDialogComponent>) { }

  ngOnInit() {
    
  }

  async send() {
    this.loading = true;
    let resp;
    if (this.isGroup) {
      resp = await this.apiService.apiSynchronize('deleteGroup', { id: this.user.id, isDeleted: true });
    } else {
      resp = await this.apiService.apiSynchronize('deletionRequest', { userId: this.user.id });
    }
    this.loading = false;
    if (!resp) return;
    this.dialog.close(this.user.id);
  }
}
