import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatAutocompleteModule, MatChipsModule, MatFormFieldModule, MatIconModule, MatInputModule, MatOptionModule } from "@angular/material";
import { FilterUsersPipe } from "src/app/pipes/filter-users.pipe";
import { EditGroupDialogComponent } from "./edit-group-dialog/edit-group-dialog.component";
import { NewGroupDialogComponent } from "./new-group-dialog/new-group-dialog.component";

const components = [
    NewGroupDialogComponent,
    EditGroupDialogComponent
]

@NgModule({
    declarations: [
        ...components,
        FilterUsersPipe
    ],
    exports: [
        ...components
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatIconModule,
        MatOptionModule,
        MatChipsModule,
        MatAutocompleteModule,
        MatFormFieldModule,
        MatInputModule
    ],
    providers: [
        FilterUsersPipe
    ]
})
export class DialogsModule {
    constructor() { }
}