import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss']
})
export class HistoryComponent implements OnInit {

  tabTitle = 'Historial de certificados';
  navLinks = [{
    path: ['colaboradores'],
    label: 'Colaboradores',
    icon: 'person'
  }];/*,
  {
    path: ['grupos'],
    label: 'Grupos',
    icon: 'people',
    disabled: true
  }];*/

  constructor() { }

  ngOnInit() {
  }

}
