import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { CertificateCategoryApi, CertificateCategoryInterface, UserApi } from 'src/app/services/shared/sdk';

@Component({
  selector: 'app-header',
  templateUrl: './header-admin.component.html',
  styleUrls: ['./header-admin.component.scss']
})
export class HeaderAdminComponent implements OnInit, OnDestroy {
  loading = false;
  categoriesBalance: number = 0;
  categories: CertificateCategoryInterface[];
  categoriesBalanceSubscription: Subscription;
  user;
  constructor(
    private apiService: ApiService,
    private _certificateCategories: CertificateCategoryApi
  ) {

  }

  async ngOnInit() {
    this.loading = true;
    this.user = this.apiService.user;

    //let company = await this.apiService.apiSynchronize('company');
    this.categories = await this._certificateCategories.find({
      where: {
        isDeleted: false,
        active: true
      }
    }).toPromise();
    if (this.categories) this.apiService.certCategories = this.categories as any;
    this.categoriesBalanceSubscription = this.apiService.onUpdateCategoriesBalance.subscribe(data => {
      if (!data) return;
      this.categoriesBalance = data.total;
      this.categories = data.categories;
    })
    this.apiService.setCertCategoriesBalance(this.categories);
    this.loading = false;
  }

  ngOnDestroy(): void {
    this.categoriesBalanceSubscription.unsubscribe();
  }

  async logout() {
    this.loading = true;
    await this.apiService.logout();
    this.loading = false;
  }

  get percentage() {
    return 100;
  }
}
