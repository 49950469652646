import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { ApiService } from 'src/app/services/api.service';


@Component({
  selector: 'app-cert-resume-dialog',
  templateUrl: './cert-resume-dialog.component.html',
  styleUrls: ['./cert-resume-dialog.component.scss']
})
export class CertResumeDialogComponent implements OnInit {
  static box = {
    width: '90%',
    height: '90vh',
    maxWidth: '980px',
    maxHeight: '350px'
  };
  loading = false;
  schedule = [];
  categoryBalance: number = 0;
  //Ahora = moment(new Date).add(-6,'hours')
  Ahora;
  constructor(private apiService: ApiService,
    @Inject(MAT_DIALOG_DATA) private data,
    private dialogRef: MatDialogRef<CertResumeDialogComponent>,
    private router: Router
  ) {
    const category = data.category;
    this.categoryBalance = category.balance;
    this.schedule = data.schedule.map((s) => {
      let icon = 'person';
      let textId = 'Colaborador: ' + s.name;
      if (s.dest === 'group') {
        icon = 'people';
        textId = `Grupo: ${s.name} (${s.length} personas)`;
      }

      return {
        icon,
        textId,
        date: s.date,
        length: s.length,
        value: s.value,
        timeHour: s.timeHour
      };
    });
    console.log(this.schedule);

  }

  async ngOnInit() {
    const dateMX = await this.apiService.apiSynchronize('currentDate');
    console.log({ dateMX });
    this.Ahora = new Date(dateMX);
    console.log(this.Ahora);


  }

  get total() {
    const reduce = (t, line) => t + (line.value * line.length);
    return this.schedule.reduce(reduce, 0);
  }

  async close() {
    const scheduleFunc = s => ({
      userId: s.dest === 'user' ? s.destId : undefined,
      groupId: s.dest === 'group' ? s.destId : undefined,
      companyId: this.apiService.company.id,
      img: (s.img == "https://adminngift.s3-us-west-2.amazonaws.com/assets/images/welcome/bienvenido.png" ? "https://adminngift.s3-us-west-2.amazonaws.com/assets/images/welcome/bienvenido.png" : s.img.split('/').pop()),
      date: s.timeHour == "0" || !s.timeHour ?
        this.Ahora :
        moment(s.date).set({ 'minutes': 0, 'seconds': 0, 'milliseconds': 0 }),
      value: s.value,
      certificateCategoryId: s.certCategoryId,
      description: s.description,
      expirationDate: moment(s.expirationDate).endOf('day'),
      status: s.timeHour == "0" || !s.timeHour ?
        'assigned' :
        s.status
    });

    let data;

    if (this.data.patch) {
      data = scheduleFunc(this.data.schedule[0]);
    } else {
      data = this.data.schedule.map(scheduleFunc);
    }
    this.loading = true;
    let response = await this.apiService
      .apiSynchronize(this.data.patch ? 'editSchedule' : 'sendSchedule', data);
    this.loading = false;
    if (!response) return false;
    //this.apiService.company.balance = response.newBalance;
    this.dialogRef.close(response);
    this.router.navigate(['/envios-programados']);
  }

}
