import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import * as moment from "moment";
import { CalendarDialogComponent } from 'src/app/components';
import { ApiService } from 'src/app/services/api.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CertificateCategoryApi } from 'src/app/services/shared/sdk';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit {

  loading = false;
  newCatShow = false;
  designs;
  categories;

  newCategory = new FormGroup({
    name: new FormControl(null, [Validators.required]),
    date: new FormControl(null),
    description: new FormControl(null, [Validators.required]),
  });

  constructor(
    private apiService: ApiService,
    private dialog: MatDialog,
    private _certificateCategory: CertificateCategoryApi
  ) { }

  async ngOnInit() {
    //if (this.apiService.certCategories.length) return;
    this.loading = true;
    let categories = await this.apiService.apiSynchronize('certCategories');
    this.loading = false;
    if (!categories) return;
    const balances = await this._certificateCategory.getBalance().toPromise();
    //console.log({ balances });

    categories.forEach(cat => {
      cat.balance = {};
      const catBalance = balances.filter(b => b.id == cat.id);
      catBalance.map(b => Object.defineProperty(cat.balance, b.label, {
        value: b.value
      }))
      //console.log({ catBalance });

      cat.name = cat.isValidated ? cat.name : cat.name + ' (Pendiente de aprobación)'
      if (cat.designs && cat.designs.length > 0) {
        let designsArray = '';
        cat.designs.forEach((des: any, index) => {
          if (index == 0) {
            designsArray = des;
          }
          else {
            designsArray = designsArray + ', ' + des;
          }
        });
        cat.desArray = designsArray;
      }
      else { cat.desArray = 'No tiene diseños' }
    });
    console.log(categories)
    this.categories = this.apiService.certCategories = categories;
  }

  openDialogNewCategory() {
    this.newCatShow = true;
    // scroll
  }

  get _categories() {
    return this.apiService.certCategories;
  }

  date(d) {
    if (!d) return 'N/A';
    let theDate = moment(d);
    if (theDate.isValid()) return theDate.format('DD [de] MMMM');
    return d;
  }

  openCalendarDialog() {
    this.dialog.open(CalendarDialogComponent, {
      ...CalendarDialogComponent.box,
      data: {
        date: this.newCategory.get('date').value
      }
    }).afterClosed().subscribe(({ date } = { date: null }) => {
      this.newCategory.get('date').setValue(date);
    });
  }

  get dateForm() {
    let date = moment(this.newCategory.get('date').value);
    if (!date.isValid()) return '';
    return date.format('DD/MM/YYYY');
  }

  async sendRequest() {
    let newCat = {
      name: this.newCategory.get('name').value,
      companyId: this.apiService.company.id,
      isValidated: false,
      description: this.newCategory.get('description').value,
      dateDefault: this.newCategory.get('date').value
    };

    this.loading = true;
    let resp = await this.apiService.apiSynchronize('requestNewcategory', newCat);
    this.loading = false;
    if (!resp) return;
    console.log(resp);

    this.newCatShow = false;
    this.newCategory.reset();
    this.ngOnInit();
  }
}
