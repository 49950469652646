import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import * as moment from "moment";
import { ApiService } from 'src/app/services/api.service';
import {
  CalendarDialogComponent,
  CertCategoriesDialogComponent,
  CertResumeDialogComponent
} from 'src/app/components';

@Component({
  selector: 'app-certs',
  templateUrl: './certs.component.html',
  styleUrls: ['./certs.component.scss']
})
export class CertsComponent implements OnInit {
  loading = false;
  onlyOne = false;
  total2 = 0;
  certCategories = [];
  cert: {
    id: number;
    img: string;
    title?: string;
    description: string;
    expirationDate?: Date;
    value: number;
    category: { id: number, images: string[], templates: object[] };
  } = {
      id: 0,
      img: '',
      title: '',
      description: null,
      expirationDate: null,
      value: 0,
      category: null
    };


  schedule: {
    id?: number;
    dest: string;//'group' | 'user',
    destId: number;
    date: Date;
    status?: String;
    timeHour: number;
    value: number;
    userGroupIndex: number;
    certCategoryId?: number;
    description?: string;
    img?: string;
    name?: string;
    expirationDate: Date;
    certHours?: any[]
  }[] = [];
  dropdownSettings;
  dropdownSettings2;

  minExpirationDate = new Date()

  users = [];
  groups = [];

  constructor(private apiService: ApiService, private dialog: MatDialog) { }

  async ngOnInit() {
    this.loading = true;
    let [schedule,
      certCategories,
      certTemplates,
      users,
      groups
    ] = await Promise.all([
      history.state.data,
      this.apiService.apiSynchronize('certCategories'),
      this.apiService.apiSynchronize('certTemplates'),
      this.apiService.apiSynchronize('users'),
      this.apiService.apiSynchronize('groupNames')
    ]);
    this.loading = false;
    if (certCategories) {
      certCategories.forEach((certCat: any) => {
        certCat.images = [];
        certCat.templates = [];
        if (certCat.certificateDesigns && certCat.certificateDesigns.length) {
          //certCat.images = certCat.certificateDesigns.map((cert) =>  this.apiService.bucket(cert.image));
          certCat.certificateDesigns.forEach(element => {
            if (element.image == "https://adminngift.s3-us-west-2.amazonaws.com/assets/images/welcome/bienvenido.png") {
              certCat.images.push(element.image)
            }
            else {
              certCat.images.push(this.apiService.bucket(element.image))
            }
          });
        }
        if (certTemplates) {

          certTemplates.forEach(temp => {
            temp.categories.forEach(ct => {
              if (ct == certCat.name) {
                certCat.templates.push(temp);
              }
            });
          });

        }
      });
      this.apiService.certCategories = certCategories;
      this.certCategories = certCategories;
    }
    if (certTemplates) {
      this.apiService.certTemplates = certTemplates;
    }
    if (users) {
      users.forEach((user, index) => {
        user.index = index
      });
      this.apiService.users = users;
      this.users = users;

    }
    if (groups) {
      this.apiService.groups = groups;
      groups.forEach((user, index) => {
        user.index = index
      });
      this.groups = groups;
    }
    this.addLineSchedule();
    if (schedule) {
      this.onlyOne = true;
      let hour = moment.utc(schedule.date).get('hour');
      let category = this.certCategories
        .find(cc => cc.id == schedule.certificateCategoryId);
      let dest = (schedule.userId ? 'user' : 'group');
      let destId = schedule.userId || schedule.groupId;
      let userGroupIndex = this.apiService[dest + 's'].findIndex(sg => sg.id === destId);

      this.cert = {
        id: 0,
        img: this.apiService.bucket(schedule.img),
        description: schedule.description,
        value: schedule.value,
        category
      };
      // if(schedule.img == "https://adminngift.s3-us-west-2.amazonaws.com/assets/images/welcome/bienvenido.png"){
      //   this.cert.img == "https://adminngift.s3-us-west-2.amazonaws.com/assets/images/welcome/bienvenido.png";
      // }
      this.schedule[0] = ({
        id: schedule.id,
        dest,
        destId,
        date: new Date(schedule.date),
        timeHour: hour || null,
        value: schedule.value,
        userGroupIndex,
        certCategoryId: schedule.certificateCategoryId,
        description: schedule.description,
        img: schedule.img,
        expirationDate: schedule.expirationDate
      });
      this.addDataLine(this.schedule[0]);

    }

    this.dropdownSettings = {
      singleSelection: true,
      idField: 'index',
      textField: 'email',
      selectAllText: 'Seleccionar todo',
      unSelectAllText: 'Deseleccionar',
      itemsShowLimit: 20,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'Sin datos'
    };

    this.dropdownSettings2 = {
      singleSelection: true,
      idField: 'index',
      textField: 'name',
      selectAllText: 'Seleccionar todo',
      unSelectAllText: 'Deseleccionar',
      itemsShowLimit: 20,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'Sin datos'
    };

  }

  removeLineSchedule(index) {
    this.schedule.splice(index, 1);
  }

  addLineSchedule() {
    this.schedule.push({
      dest: null,
      destId: null,
      date: this.newDefaultDate(),
      timeHour: null,
      value: null,
      userGroupIndex: null,
      expirationDate: null,
      certHours: this.getCertAvailableHours(this.newDefaultDate())
    });
  }

  getCertAvailableHours(certDate: Date): any[] {
    let currentDate = moment().utc()
    let hours = new Array(21).fill(null).map((hour, index) => ({
      value: index,
      label: moment().set({ hour: index, minute: 0 }).format('HH:mm a'),
      enable: moment(certDate).isSame(currentDate, 'date') && moment(currentDate).isSameOrAfter(moment().set({ hour: index, minute: 0 }), 'hour') ? false : true
    })).filter(hour => hour.value >= 6)
    if (moment(certDate).isSame(currentDate, 'date')) hours.unshift({
      value: 0,
      label: 'Ahora',
      enable: true
    })

    return [...hours]
  }

  newDefaultDate() {
    let date = moment().utc().set({ minute: 0 });
    return date.toDate();
  }

  addDataLine(line) {
    let dest = this.apiService[line.dest + 's'][line.userGroupIndex[0].index];
    line.destId = dest.id;
    line.email = dest.email;
    line.name = dest.name;
    line.length = 1;
    if (line.dest === 'group') {
      line.length = this.apiService.groupById(line.destId).users.length;
    }
  }

  resetLine(line) {
    line.userGroupIndex = null;
    line.destId = null;
    line.length = null;
    line.email = null;
    line.name = null;
  }

  setHour(e, line) {
    line.date = moment.utc(line.date).set({ hour: e.target.value, minute: 0 });
    line.status = e.target.value == 0 ? 'assigned' : 'accepted'
  }



  getDate(date) {
    return moment.utc(date).format('DD/MM/YYYY');
  }

  get total() {
    const reduce = (total, line) => total + (line.value * line.length);
    this.total2 = this.schedule.reduce(reduce, 0);
    return this.total2;
  }

  openCalendarDialog(line) {
    let offset = -moment().utcOffset();
    let upDate = moment.utc(line.date).add(offset, 'minutes').toDate();
    let min = moment(this.newDefaultDate()).add(offset, 'minutes').toDate();
    let data = {
      date: new Date(),
      min
    };
    const closed = (resp) => {
      if (!resp) return;
      let { date } = resp;
      date = moment.utc(date);
      line.date = date.toDate();
      line.certHours = this.getCertAvailableHours(date)
    };
    this.openDialog(CalendarDialogComponent, data, closed);
  }

  openCertCategoriesDialog() {
    let selected = this.cert.category ? {
      cat: this.cert.category.id,
      img: this.cert.img
    } : {
      cat: null,
      img: null
    };
    const closed = (selected) => {
      if (!selected) return;
      let certCat = this.certCategories
        .find(cc => cc.id === selected.cat);
      this.cert.category = certCat;
      this.cert.img = selected.img;
    };
    this.openDialog(CertCategoriesDialogComponent, selected, closed);
  }

  openResumeDialog(patch?: boolean) {
    const onClose = (response) => {
      if (!response) return;
      const categoryToUpdate = this.certCategories.find(cat => cat.id == response.certificateCategoryId);
      categoryToUpdate.balance = response.newBalance;
      this.apiService.setCertCategoriesBalance(this.certCategories);
    }
    this.openDialog(CertResumeDialogComponent, {
      schedule: this.schedule.map(s => {
        s.certCategoryId = this.cert.category.id;
        s.img = this.cert.img;
        s.description = this.cert.description;
        s.expirationDate = this.cert.expirationDate;
        s.status = s.status;
        return s;
      }),
      patch,
      category: this.certCategories.find(cat => cat.id == this.cert.category.id)
    }, onClose);
  }

  openDialog(Dialog, data?: Object, afterClosed?) {
    let dialog = this.dialog.open(Dialog, {
      ...Dialog.box,
      data
    })
    if (afterClosed) {
      dialog.afterClosed().subscribe(afterClosed);
    }
  }

  isValid() {
    let condition = !!this.cert.img;
    if (!this.cert || !this.cert.category) return false;
    const selectedCategory = this.certCategories.find(cat => cat.id == this.cert.category.id)
    condition = condition && selectedCategory.balance >= this.total2;
    condition = condition && !!this.cert.description;
    condition = condition && this.schedule.length > 0;
    if (condition) {
      for (let i = 0; i < this.schedule.length; i++) {
        let vals = ['destId', 'date', 'value', 'name', 'length'];
        condition = vals.reduce((con, valName) => con && !!this.schedule[i][valName], condition);
        if (!condition) break;
      }
    }
    return condition;
  }

  isBalanceValid() {
    if (!this.cert || !this.cert.category) return false;
    const selectedCategory = this.certCategories.find(cat => cat.id == this.cert.category.id);
    return selectedCategory.balance >= this.total2;
  }

  get _expirationDate() {
    if (!this.cert.expirationDate) return 'dd/mm/aaaa';
    return moment(this.cert.expirationDate).format('L');
  }

}
