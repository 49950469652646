import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-group-users-dialog',
  templateUrl: './group-users-dialog.component.html',
  styleUrls: ['./group-users-dialog.component.scss']
})
export class GroupUsersDialogComponent implements OnInit {

  static box = {
    width: '90%',
    maxWidth: '600px',
    maxHeight: '80vh'
  };

  constructor(@Inject(MAT_DIALOG_DATA) private group) { }

  ngOnInit() {
  }

}
