import { Component, OnInit } from '@angular/core';
import { formatNumber } from '@angular/common';
import * as moment from 'moment';
import { MatDialog } from '@angular/material';
import { ApiService } from 'src/app/services/api.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CalendarDialogComponent } from 'src/app/components';
import { dashCaseToCamelCase } from '@angular/compiler/src/util';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss']
})
export class EventsComponent implements OnInit {
  p: number = 1;
  loading = false;
  newEventShow = false;
  eventos = [];

  newEvent = new FormGroup({
    place: new FormControl(null, [Validators.required]),
    date: new FormControl(new Date()),
    startTime: new FormControl(null, [Validators.required]),
    campus: new FormControl(null, [Validators.required]),
  });

  constructor(
    private apiService: ApiService,
    private dialog: MatDialog
  ) { }

  async ngOnInit() {
    if (this.apiService.events.length) return;
    this.loading = true;
    let events = await this.apiService.apiSynchronize('events');
    this.loading = false;
    if (!events) return;
    events.forEach(event => {
      let evento = event;
      evento.campus = event.isValidated === false ? event.campus.name + '(Confirmación pendiente)' : event.campus.name;
      this.eventos.push(evento);
    });
    this.apiService.events = events;

    if (this.apiService.campuses.length) return;
    let campuses = await this.apiService.apiSynchronize('campuses');
    if (!campuses) return;
    this.apiService.campuses = campuses;
  }

  openDialogNewEvent() {

    this.newEventShow = true;
    // scroll
  }

  get events() {
    return this.apiService.events;
  }

  date(d) {
    if (!d) return 'N/A';
    let theDate = moment(d);
    if (theDate.isValid()) return theDate.format('DD [de] MMMM');
    return d;
  }

  get dateForm() {
    let date = moment(this.newEvent.get('date').value);
    if (!date.isValid()) return '';
    return date.format('DD/MM/YYYY');
  }

  async sendRequest() {
    let newEvent = {
      place: this.newEvent.get('place').value,
      companyId: this.apiService.company.id,
      campusId: this.newEvent.get('campus').value,
      startTime: this.newEvent.get('startTime').value,
      date: this.newEvent.get('date').value,
      campus: this.apiService.campuses.find((cam) => cam.id == this.newEvent.get('campus').value).name
    };


    this.loading = true;
    let resp = await this.apiService.apiSynchronize('requestNewEvent', newEvent);
    this.loading = false;
    if (!resp) return;
    console.log(resp);

    this.newEventShow = false;
    this.newEvent.reset();
    this.ngOnInit();
  }

  openCalendarDialog() {
    this.dialog.open(CalendarDialogComponent, {
      ...CalendarDialogComponent.box,
      data: {
        date: this.newEvent.get('date').value,
        min: new Date()
      }
    }).afterClosed().subscribe(({ date }) => {

      this.newEvent.get('date').setValue(date);
    });
  }

}
