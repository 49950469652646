import { Component, OnInit, Inject } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-user-email-dialog',
  templateUrl: './user-email-dialog.component.html',
  styleUrls: ['./user-email-dialog.component.scss']
})
export class UserEmailDialogComponent implements OnInit {

  static box = {
    width: '90%',
    maxWidth: '480px'
  };
  loading = false;
  newMail = '';
  mail = '';

  constructor(private apiService: ApiService, @Inject(MAT_DIALOG_DATA) private user, private dialog: MatDialogRef<UserEmailDialogComponent>) { }

  ngOnInit() {
  }

  async send() {
    let data = { id: this.user.id, email: this.mail };
    this.loading = true;
    let resp = await this.apiService.apiSynchronize('sendNewEmail', data);
    this.loading = false;
    if (!resp) return;
    this.dialog.close();
  }

}
