import { Component, OnInit, Inject } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-forward-invitation-dialog',
  templateUrl: './forward-invitation-dialog.component.html',
  styleUrls: ['./forward-invitation-dialog.component.scss']
})
export class ForwardInvitationDialogComponent implements OnInit {

  static box = {};

  loading = false;

  constructor(private apiService: ApiService, @Inject(MAT_DIALOG_DATA) private user, private dialog: MatDialogRef<ForwardInvitationDialogComponent>) { }

  ngOnInit() {
  }

  async send() {
    this.loading = true;
    let resp = await this.apiService.apiSynchronize('resendInvitation', { userId: this.user.id });
    this.loading = false;
    if (!resp) return;
    this.dialog.close();
  }

}
